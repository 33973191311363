import React, { useState, useEffect } from 'react';
import { styled, useStyletron } from 'baseui';
import ButtonCustom from '@/components/ButtonCustom';
import { SizeButton } from '@/constants/sizeButton';
import { KIND } from 'baseui/button';
import { lightTheme } from '@/customTheme';
import WrapImage from '@/scenes/Editor/components/Toolbox/ToolboxItems/MagicBgToolSubComponent/WrapImage';
import { t } from 'i18next';

interface AsyncImageGridProps {
    onFetchImages: () => Promise<string>;
    batchSize?: number;
    aspectRatio?: number;
    loadingPlaceholderSrc?: string;
    selectedImage?: string;
    onImageSelect?: (image: string) => void;
    imageWidth?: number;
    gridGap?: number;
    initialImages?: string[];
    onLoadingChange?: (isLoading: boolean) => void;
    disableCreateMore?: boolean
    wrapScrollRef?: React.RefObject<HTMLDivElement>
}

const GridContainer = styled('div', {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    paddingBottom: '16px'
});

const ImageContainer = styled('div', (props: { $isSelected: boolean; $width: number; $height: number }) => ({
    width: `${props.$width}px`,
    height: `${props.$height}px`,
    borderRadius: '8px',
    border: `2px solid ${props.$isSelected ? lightTheme.colors.primary : 'transparent'}`,
    cursor: 'pointer',
    overflow: 'hidden',
    objectFit: 'fill',
}));

const LoadingPlaceholder = styled('div', (props: { $width: number; $height: number }) => ({
    width: `${props.$width}px`,
    height: `${props.$height}px`,
    borderRadius: '8px',
    background: 'rgb(242, 242, 242)',
    cursor: 'not-allowed',
    animation: 'fade 1s infinite',
    overflow: 'hidden',
}));

const StyledImage = styled('img', {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
});

const ButtonContainer = styled('div', {
    width: '100%',
    paddingTop: '8px'
});

const AsyncImageGrid: React.FC<AsyncImageGridProps> = ({
    onFetchImages,
    batchSize = 4,
    aspectRatio = 1,
    loadingPlaceholderSrc,
    selectedImage,
    onImageSelect,
    imageWidth = 142,
    gridGap = 8,
    initialImages = [],
    onLoadingChange,
    disableCreateMore = false,
    wrapScrollRef
}) => {
    const [images, setImages] = useState<(string | null)[]>(initialImages);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [totalSlots, setTotalSlots] = useState(Math.max(initialImages.length || batchSize));

    const fetchSingleImage = async (index: number) => {
        try {
            const result = await onFetchImages();
            setImages(prev => {
                const newImages = [...prev];
                newImages[index] = result;
                return newImages;
            });
        } catch (error) {
            console.error('Failed to fetch image:', error);
            setImages(prev => {
                const newImages = [...prev];
                newImages[index] = null;
                return newImages;
            });
        }
    };

    const fetchBatch = async (startIndex: number) => {
        setIsLoadingMore(true);

        setImages(prev => {
            const newImages = [...prev];
            for (let i = 0; i < batchSize; i++) {
                if (!newImages[startIndex + i]) {
                    newImages[startIndex + i] = null;
                }
            }
            return newImages;
        });

        const fetchPromises = Array(batchSize).fill(null).map((_, i) =>
            fetchSingleImage(startIndex + i)
        );

        
        await Promise.all(fetchPromises);
        setIsLoadingMore(false);
    };

    useEffect(() => {
        if(initialImages.length === 0){
            fetchBatch(0);
        }
    }, []);

    useEffect(() => {
        onLoadingChange?.(isLoadingMore);
    }, [isLoadingMore, onLoadingChange]);

    const loadMore = () => {
        const newStartIndex = images.length;
        setTotalSlots(prev => prev + batchSize);
        fetchBatch(newStartIndex);
    };

    const imageHeight = imageWidth / aspectRatio;

    useEffect(() => {
        // Scroll to bottom
        wrapScrollRef?.current.scrollTo({
            top: wrapScrollRef.current.scrollHeight,
            behavior: 'smooth',
        });
    }, [totalSlots])

    return (
        <div>
            <style>{`
                @keyframes fade {
                    50% {
                        opacity: 0.5;
                    }
                }
            `}</style>
            <GridContainer>
                {Array.from({ length: totalSlots }).map((_, index) => {
                    const image = images[index];

                    if (image === null || image === undefined) {
                        return (
                            <LoadingPlaceholder
                                key={index}
                                $width={imageWidth}
                                $height={imageHeight}
                            >
                                {loadingPlaceholderSrc && (
                                    <WrapImage srcImage={new URL(loadingPlaceholderSrc)} hasHoverState={false}/>
                                )}
                            </LoadingPlaceholder>
                        );
                    }

                    return (
                        <ImageContainer
                            key={index}
                            $isSelected={image === selectedImage}
                            $width={imageWidth}
                            $height={imageHeight}
                            onClick={() => onImageSelect?.(image)}
                        >
                            <WrapImage srcImage={new URL(image)}/>
                        </ImageContainer>
                    );
                })}

                <ButtonContainer>
                    <ButtonCustom
                        type={SizeButton.LARGE}
                        kind={KIND.secondary}
                        onClick={loadMore}
                        style={{
                            background: isLoadingMore || disableCreateMore ? lightTheme.colors.grayScale50 : 'auto',
                            pointerEvents: isLoadingMore || disableCreateMore ? 'none' : 'auto',
                        }}
                    >
                        <span style={{
                            ...lightTheme.typography.Small14Semibold,
                            color: isLoadingMore || disableCreateMore ? '#CCCCCC' : '#666666',
                        }}>
                            {t('Create more')}
                        </span>
                    </ButtonCustom>
                </ButtonContainer>
            </GridContainer>
        </div>
    );
};

export default AsyncImageGrid;