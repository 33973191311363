import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill ? props.fill : "#fff"}
      d="m11.082 2.371 5.385 5.317c.376.364.533.705.533 1.135 0 .446-.17.8-.533 1.151l-5.385 5.302a1.328 1.328 0 0 1-.979.402c-.74 0-1.301-.57-1.301-1.336 0-.383.149-.736.418-.975l1.857-1.782 1.836-1.478-3.338.162H2.014c-.824 0-1.403-.608-1.403-1.446 0-.825.579-1.442 1.403-1.442h7.561l3.326.162-1.807-1.45L9.22 4.295a1.346 1.346 0 0 1-.418-.974c0-.767.561-1.321 1.301-1.321.397 0 .703.107.979.371Z"
    />
  </svg>
)
export default SvgComponent
