import { lightTheme } from '@/customTheme'
import { useStyletron } from 'baseui'
import { LabelLarge } from 'baseui/typography'
import React from 'react'
import WrapImage from './WrapImage'
import { useSelector } from 'react-redux'
import { selectUser } from '@/store/slices/user/selectors'

function Categories({ categories, onClickCategory, suggestImageLoaded }) {
  const [css] = useStyletron()
  const user = useSelector(selectUser)

  return (
    <>
      {categories.length ? categories.map(category => (
        <div key={category.title}>
          <div
            style={{
              height: '26px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '8px',
            }}
          >
            <LabelLarge $style={{ ...lightTheme.typography.Small14Semibold, color:lightTheme.colors.text.text_black_title }}>{category.title}</LabelLarge>
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              columnGap: '8px',
              rowGap: '36px',
            }}
          >
            {category.data.map(
              (item, index) =>
                index < 8 && (
                  <div
                    onClick={() => {
                      onClickCategory(item)
                    }}
                    key={item.title}
                    className={css({
                      width: '67px',
                      height: '67px',
                      borderRadius: '8px',
                      background: 'rgb(242, 242, 242)',
                      border: '1px solid rgba(0,0,0,0.1)',
                      display: 'flex',
                      flexDirection: 'column',
                      cursor: 'pointer',
                      position: 'relative',
                      boxSizing: 'border-box',
                      ':hover div': {
                        display: 'block',
                      },
                      pointerEvents: suggestImageLoaded ? 'auto' : 'none'
                    })}
                  >
                    <WrapImage srcImage={item.assets[0].file} resize={[65, 65]} withCrown={!user?.userIsPremium} suggestImageLoaded={suggestImageLoaded} />
                    <p
                      style={{
                        position: 'absolute',
                        margin: 0,
                        top: `calc(100% + 8px)`,
                        left: 0,
                        right: 0,
                        textAlign: 'center',
                        fontSize: lightTheme.typography.Small11medium.fontSize,
                        fontWeight: lightTheme.typography.Small11medium.fontWeight,
                        lineHeight: lightTheme.typography.Small11medium.lineHeight,
                        color: lightTheme.colors.grayScale600,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {item.title}
                    </p>
                  </div>
                )
            )}
          </div>
        </div>
      )) : null}
    </>
  )
}

export default Categories