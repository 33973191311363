import { createAction } from '@reduxjs/toolkit'

export const setUser = createAction<any>('user/setUser')
export const setUserIsPremium = createAction<boolean>('user/setUserIsPremium')
export const setOpenModalTryPremium = createAction<{
  isOpen: boolean
  source: string | null
  callback?: Function
  opened?: boolean
  video?: string
  placeholderImage?: string
}>('user/setOpenModalTryPremium')
export const setIntroVideoDetail = createAction<{
  isOpen: boolean
  title: string
  subtitle: string
  videoSrc: string
  poster?: string
  contentBtn?: string
  onClickButton: Function
}>('user/setIntroVideoDetail')
export const setIsSampleUser = createAction<boolean>('user/setIsSampleUser')
