import { useContext, useEffect, useRef, useState } from 'react'
import { EditorContext, RemoveEditorContext } from '@/scenes/engine'
import useAppContext from '@/hooks/useAppContext'
import { lightTheme } from '@/customTheme'
import EnhanceObjectHandler from '@/scenes/engine/handlers/remove-handler/EnhanceObjectHandler'
import { StaticImage } from 'fabric/fabric-impl'
import { MediaImageRepository } from '@/scenes/engine/objects/media-repository/media_image_repository'
import { MediaImageType } from '@/scenes/engine/objects/media-repository/media_image_type'
import api from '@/services/api'
import { MediaImageRepositoryProcessing, ResizeMode, ResizeOptions } from '@/scenes/engine/objects/media-repository/media_image_repository_processing'
import { useTranslation } from 'react-i18next'
import { AutoAdjustManager } from '@/scenes/engine/utils/autoAdjustManager'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { customAmplitude } from '@/utils/customAmplitude'
import ModalEnhanceError from '@/components/ShowModal/ModalEnhanceError'
import { useAppDispatch } from '@/store/store'

function EnhanceTool() {
  const removeEditor = useContext(RemoveEditorContext).editor
  const { setIsLoadedJsonSuccess } = useAppContext()
  const { setisOpenPixelManipulationObject, isOpenPixelManipulationObject, toolType, setShowModalType, cancelPixelManipulation } = useAppContext()
  const [toolActive, setToolActive] = useState('before')
  const [isLoadedResult, setIsLoadedResult] = useState(false)
  const isLoadedResultRef = useRef(isLoadedResult);
  const { canvas } = useContext(RemoveEditorContext)
  const { activeObject } = useContext(EditorContext)
  const Base64Prefix = 'data:image/png;base64,'
  const imageProcessing = new MediaImageRepositoryProcessing()
  const dispatch = useAppDispatch()
  const endAnimationRef = useRef(false);
  const directionAnimationRef = useRef(1);
  const { t } = useTranslation()


  useEffect(() => {
    removeEditor.handlers.setPixelToolType('Enhance')
    setisOpenPixelManipulationObject(true)

    if(activeObject) {
      getResultEnhance(activeObject)
    }
  }, [])


  useEffect(() => {
    if (!isOpenPixelManipulationObject || !removeEditor || toolType !== 'Enhance') {
      return
    }
    let canvasRemoveTool = document.getElementById('wrap-canvas-remove-tool')

    ;(async () => {
      removeEditor.handlers.pixelManipulationObjectHandler.activeObject = activeObject
      await removeEditor.handlers.pixelManipulationObjectHandler.setupImage()
       await removeEditor.handlers.pixelManipulationObjectHandler.addImageToCanvas()
       // duplicate image so it can be animated to fade afterwards
       await removeEditor.handlers.pixelManipulationObjectHandler.addImageToCanvas()

      canvasRemoveTool.style.zIndex = '2'
      let enhanceObjectHandler = removeEditor.handlers.pixelManipulationObjectHandler as EnhanceObjectHandler
      enhanceObjectHandler.handleAnimate().then(()=>{
        setWiperAnimation()
      })
    })()
  }, [isOpenPixelManipulationObject, toolType])

  const getResultEnhance = async (activeObject) => {
    let refObject = activeObject as unknown as StaticImage

    // @ts-ignore
    let id = refObject.id as string
    let assetStateId = refObject.layerAssetStateId as string
    // @ts-ignore
    let originalImage = await MediaImageRepository.getInstance().getImage(
      id,
      assetStateId,
      MediaImageType.original
    )
    refObject.isLatest = false
    
    const base64 = await imageProcessing.blobUrlToBase64(originalImage)
    
    try {
      let data = await api.enhance(base64)
      await handleEnhanceImage(data.result)
      dispatch(()=>{
        setIsLoadedResult(true)
      })
      customAmplitude('AI enhance api call')
    } catch(err) {
      console.log('err', err)
      dispatch(()=>{
        setIsLoadedResult(true)
      })
      setShowModalType(ShowModalType.ENHANCE_ERROR)
      customAmplitude('AI enhance api error')
    }
  }


  const handleEnhanceImage = async (data) => {
    let refObject = activeObject as unknown as StaticImage
    //@ts-ignore
    let id = refObject.id as string
    let assetStateId = refObject.layerAssetStateId as string
    let mask = await MediaImageRepository.getInstance().getImage(
      id,
      assetStateId,
      MediaImageType.mask
    )
    
    let resizeOptions: ResizeOptions = {
            allowUpsampling: false,
            exportType: 'image/png',
            pad: false,
            resizeMode: ResizeMode.aspectFit
          }
          
    let resizedBlobUrlImage = await imageProcessing.resizeBlobToMaxEdgeSize(
      Base64Prefix+data, 
      2048,
      null,
      resizeOptions
    );

    let resizedBase64Image = Base64Prefix+(await imageProcessing.blobUrlToBase64(resizedBlobUrlImage));
    let result = await imageProcessing.generateLatestImageInfo(resizedBase64Image, mask, null)
    let flatImage = result.latestImage.toDataURL()

    let autoAdjustManager = new AutoAdjustManager();
    let base64_cal_image = await autoAdjustManager.adjust(flatImage);
    let enhanceObjectHandler = removeEditor.handlers.pixelManipulationObjectHandler as EnhanceObjectHandler
    enhanceObjectHandler.imageResult = base64_cal_image

    let objEnhance = canvas.getObjects()[1] as unknown as StaticImage
    if(!objEnhance) {
      return
    }
    // @ts-ignore
    if(!objEnhance._originalScaleX && !objEnhance._originalScaleY) {
      // @ts-ignore
      objEnhance._originalScaleX = objEnhance.scaleX
      // @ts-ignore
      objEnhance._originalScaleY = objEnhance.scaleY
    }
    dispatch(()=>{
      objEnhance.replaceImage(base64_cal_image, true).then(() => {
        objEnhance.useNewTextureNextTime()
        canvas.renderAll();
        removeEditor.handlers.transactionRemoveHandler.save()
      })
      setIsLoadedJsonSuccess(true)
    })
  }

  async function setWiperAnimation() {
    directionAnimationRef.current = 1;
    const containerElement = document.querySelector(
        '#wrap-canvas-remove-tool .remove-container-class'
    );

    if (!containerElement) return;

    const overlay = document.createElement('div');
    overlay.id = 'overlay';
    Object.assign(overlay.style, {
        position: 'absolute',
        inset: '0',
        background: 'rgba(0, 0, 0, 0.5)',
        opacity: 0,
        transition: 'opacity 0.5s ease-in-out'
    });
    containerElement.appendChild(overlay);
    
    // Trigger the fade-in effect after a small delay
    setTimeout(() => {
        overlay.style.opacity = '1';
    }, 1000);
    

    const wiper = document.createElement('div');
    wiper.id = 'wiper';
    Object.assign(wiper.style, {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '5px',
        height: '100%',
        background: '#FFF',
        boxShadow: '0px 0px 20.1px 7px rgba(255, 255, 255, 0.80)',
        zIndex: '2',
        opacity: '1',
        transition: 'opacity 0.5s ease-in-out'
    });
    containerElement.appendChild(wiper);

    let start = null;
    const duration = 2000;
    const delayBetweenCycles = 200; // Delay before switching directions

    function easeInOutCubic(t) {
        return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
    }

    async function animateWiper(timestamp) {
        if (!start) start = timestamp;
        const progress = (timestamp - start) / duration;
        const easedProgress = easeInOutCubic(Math.min(progress, 1));

        wiper.style.left = directionAnimationRef.current === 1
            ? `${easedProgress * 100}%`
            : `${100 - easedProgress * 100}%`;

        if (endAnimationRef.current) {
            endAnimationRef.current = false;
            return;
        }

        if (progress < 1) {
            requestAnimationFrame(animateWiper);
        } else {
            start = null;

            directionAnimationRef.current *= -1;
            if (isLoadedResultRef.current && directionAnimationRef.current == -1) {
              exitAnimation()
              return
            }

            setTimeout(() => {
              requestAnimationFrame(animateWiper);
            }, delayBetweenCycles); // Delay before reversing
        }
    }

    setTimeout(() => {
        requestAnimationFrame(animateWiper);
    }, 2000);
}


  useEffect(() => {
    return () => {
      const containerElement = document.querySelector(
        '#wrap-canvas-remove-tool .remove-container-class'
      ) as HTMLElement
      const wiper = containerElement.querySelector("#wiper");
      const overlay = containerElement.querySelector("#overlay");

      if (overlay) {
        containerElement.removeChild(overlay)
      }
      if (wiper) {
        containerElement.removeChild(wiper)
      }
      
      endAnimationRef.current = true
    }
  }, [])

  useEffect(() => {
    isLoadedResultRef.current = isLoadedResult
  }, [isLoadedResult])





const Star = () => (
  `<svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.5 0L33.6574 19.3426L53 26.5L33.6574 33.6574L26.5 53L19.3426 33.6574L0 26.5L19.3426 19.3426L26.5 0Z" fill="white"/>
  </svg>`
)

const exitAnimation = async () => {
    endAnimationRef.current = true;
    const containerElement = document.querySelector(
        '#wrap-canvas-remove-tool .remove-container-class'
    ) as HTMLElement;

    if (!containerElement) return;

    const overlay = containerElement.querySelector('#overlay') as HTMLElement;
    const wiper = document.getElementById('wiper') as HTMLElement;

    if (!overlay || !wiper) return;
  
  const steps = [
    { 
      from: 0, 
      to: 25, 
      duration: 1000, 
      delay: 1, 
      easing: "ease-out", 
      images: [
        { scale: 0.7, percentageX: 75, percentageY: 5 }
      ]
    },
    { 
      from: 25, 
      to: 50, 
      duration: 600, 
      delay: 1, 
      easing: "ease-in-out", 
      images: [
        { scale: 1, percentageX: 70, percentageY: 80 },
        { scale: 0.5, percentageX: 80, percentageY: 30 },
      ]
    },
    { 
      from: 50, 
      to: 83, 
      duration: 300, 
      delay: 1, 
      easing: "ease-out", 
      images: [
        { scale: 0.6, percentageX: 30, percentageY: 70 },
        { scale: 0.7, percentageX: 80, percentageY: 40  },
        { scale: 1, percentageX: 20, percentageY: 10  }
      ]
    },
    { 
      from: 83, 
      to: 100, 
      duration: 600, 
      delay: 1, 
      easing: "ease-in-out", 
      images: []
    },
  ];
  
    let currentStep = 0;

    // Easing functions
    function easeIn(t: number) {
        return t * t;
    }

    function easeOut(t: number) {
        return t * (2 - t);
    }

    function easeInOut(t: number) {
        return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
    }

    // Select easing function based on the configuration
    function getEasingFunction(easing: string) {
        switch (easing) {
            case "ease-in":
                return easeIn;
            case "ease-out":
                return easeOut;
            case "ease-in-out":
                return easeInOut;
            default:
                return easeInOut;
        }
    }

    function animateStep(startTime: number, from: number, to: number, duration: number, delay: number, easing: string, images: Array<any>) {
        const easingFunction = getEasingFunction(easing);

        // Create images (SVG components) and apply fade-in effect
        const imageElements: Array<HTMLElement> = [];
        const fadeOutDelay = 1000; // Configurable delay before fade out (in ms)
        
        images.forEach((image, index) => {
          // Create a container for the SVG image
          const imgContainer = document.createElement("div");
          imgContainer.style.position = "absolute";
          imgContainer.style.width = `53px`;
          imgContainer.style.height = `53px`;
          imgContainer.style.scale = `${image.scale}`;
          imgContainer.style.left = `${directionAnimationRef.current === 1 ? 90 - image.percentageX : image.percentageX}%`;
          imgContainer.style.top = `${image.percentageY}%`;
          imgContainer.style.opacity = "0";  // Initially hidden
          imgContainer.style.transition = "opacity 0.1s ease-in-out";  // Fade-in/out duration
        
          containerElement.appendChild(imgContainer);
          imgContainer.innerHTML = Star();
          imageElements.push(imgContainer);
        
          // Fade-in the image by setting opacity to 1 (start animation)
          setTimeout(() => {
            imgContainer.style.opacity = "1";

            // After the fade-in, fade-out the image after a delay (fadeOutDelay)
          }, index * 200); // Delay each image's fade-in slightly for visual effect

          setTimeout(() => {
            imgContainer.style.opacity = "0";  // Fade-out
            setTimeout(()=>{
              imgContainer.remove()
            }, 500)
          }, fadeOutDelay /*+ index * 200*/); // Add delay before fading out each image
        });
        
        function stepAnimation(timestamp: number) {
            const elapsed = timestamp - startTime;
            const progress = Math.min(elapsed / duration, 1);
            const easedProgress = easingFunction(progress);

            const newPosition = from + (to - from) * easedProgress;
            wiper.style.left = `${directionAnimationRef.current === 1 ? newPosition : 100 - newPosition}%`;

            if (directionAnimationRef.current === 1) {
                overlay.style.left = `${newPosition}%`;
            } else {
                overlay.style.right = `${newPosition}%`;
            }

            // Apply fade-in effect to images based on current progress
            imageElements.forEach(img => {
                img.style.opacity = `${Math.min(easedProgress, 1)}`; // Fade in based on progress
            });

            if (progress < 1) {
                requestAnimationFrame(stepAnimation);
            } else {
                // Move to next step after the configured delay
                currentStep++;
                if (currentStep < steps.length) {
                    setTimeout(() => {
                        requestAnimationFrame((t) => animateStep(t, steps[currentStep - 1].to, steps[currentStep].to, steps[currentStep].duration, steps[currentStep].delay, steps[currentStep].easing, steps[currentStep].images));
                    }, delay);
                } else {
                    setTimeout(() => {
                        // Clean up images after the animation
                        imageElements.forEach(img => {
                            img.style.opacity = "0";  // Fade out the image
                        });

                        setTimeout(() => {
                            imageElements.forEach(img => {
                                containerElement.removeChild(img);
                            });
                            wiper.style.opacity = '0'
                            setTimeout(()=>{
                              containerElement.removeChild(overlay);
                              containerElement.removeChild(wiper);

                              dispatch(()=>{setToolActive('after')})
                            }, 700)
                        }, 500);  // Delay for fade-out
                    }, delay);
                }
            }
        }
        requestAnimationFrame(stepAnimation);
    }

    // Start animation from the first step
    requestAnimationFrame((t) => animateStep(t, steps[0].from, steps[0].to, steps[0].duration, steps[0].delay, steps[0].easing, steps[0].images));
};


  return (
    <div style={{ position: 'relative' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
        <div
          style={{
            display: 'flex',
            gap: '4px',
            padding: '6px',
            background: lightTheme.colors.grayScale50,
            borderRadius: '26px',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '6px',
              padding: '8px 0',
              background: toolActive === 'before' ? '#fff' : 'transparent',
              borderRadius: '20px',
              cursor: 'pointer',
              width: '100%',
              justifyContent: 'center',
              pointerEvents: isLoadedResult ? 'auto' : 'none',
            }}
            onClick={() => {
              dispatch(()=>{
                setToolActive('before')
              })
              const objEnhance = canvas.getObjects()[0]
              objEnhance.moveTo(1)
              removeEditor.emit('remove-history:changed', { hasUndo: false })
            }}
          >
            <span
              style={{
                ...lightTheme.typography.Small14Semibold,
                color: !isLoadedResult ? lightTheme.colors.grayScale200 : toolActive === 'before' ? lightTheme.colors.blackGray : 'rgba(106, 106, 106, 1)',
              }}
            >
              {t('Original')}
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '6px',
              padding: '8px 0',
              background: toolActive === 'after' ? '#fff' : 'transparent',
              borderRadius: '20px',
              cursor: 'pointer',
              width: '100%',
              justifyContent: 'center',
              pointerEvents: isLoadedResult ? 'auto' : 'none',
            }}
            onClick={async () => {
              dispatch(()=>{
                setToolActive('after')
              })
              const objEnhance = canvas.getObjects()[1]
              objEnhance.moveTo(0)
              removeEditor.emit('remove-history:changed', { hasUndo: true })
            }}
          >
            <span
              style={{
                ...lightTheme.typography.Small14Semibold,
                color: !isLoadedResult ? lightTheme.colors.grayScale200 : toolActive === 'after' ? lightTheme.colors.blackGray : 'rgba(106, 106, 106, 1)',
              }}
            >
              {t('Enhanced')}
            </span>
          </div>
        </div>
      </div>
      <ModalEnhanceError />
    </div>
  )
}

export default EnhanceTool
