export const panelListItems = [
  {
    id: 'templates',
    name: 'Templates',
  },
  {
    id: 'ai-image',
    name: 'AI Image',
    disable: false,
  },
  {
    id: 'photos',
    name: 'Photos',
    disable: false,
  },
  {
    id: 'text',
    name: 'Text',
    disable: false,
  },
  {
    id: 'stock',
    name: 'Stock',
    disable: false,
  },
  // {
  //   id: 'image',
  //   name: 'Images',
  // },
  // {
  //   id: 'uploads',
  //   name: 'Uploads',
  // },
  // {
  //   id: 'background',
  //   name: 'Background',
  //   disable: false,
  // },
  // {
  //   id: 'vectors',
  //   name: 'Vectors',
  //   disable: false,
  // },
  // {
  //   id: 'overlay',
  //   name: 'Overlay',
  //   disable: false,
  // },
  {
    id: 'graphics',
    name: 'Graphics',
    disable: false,
  },
  // {
  //   id: 'pixabay',
  //   name: 'Pixabay',
  // },
]

export enum PanelType {
  // TEMPLATES = 'Templates',
  BACKGROUND = 'Background',
  TOOLBOX = 'Toolbox',
}

export enum PopoverType {
  PHOTOS = 'PHOTOS',
  STOCK = 'STOCK',
  GRAPHICS = 'GRAPHICS',
  TEMPLATES = 'TEMPLATES',
  TEXT = 'TEXT',
  AI_IMAGE = 'AI_IMAGE',
}

export interface DisableEditorState {
  disableInspector: boolean;
  disableSidebar: boolean;
  hideSidebar: boolean;
  canvasOverlay: boolean;
  disableNavbar: boolean;
  hideTooltip: boolean;
};

// Define the default value with all properties set to true
export const defaultEnabledEditorState: DisableEditorState = {
  disableInspector: false,
  disableSidebar: false,
  hideSidebar: false,
  canvasOverlay: false,
  disableNavbar: false,
  hideTooltip: false
};

export const defaultDisabledEditorState: DisableEditorState = {
  disableInspector: true,
  disableSidebar: true,
  hideSidebar: false,
  canvasOverlay: true,
  disableNavbar: true,
  hideTooltip: false
};