import { panelListItems, PopoverType } from '@/constants/app-options'
import useAppContext from '@/hooks/useAppContext'
import React, { useState } from 'react'
import PanelListItem from '../../Panels/PanelListItem'
import AIImage from '../../Panels/PanelItems/AIImage'
import { useTranslation } from 'react-i18next'
import { lightTheme } from '@/customTheme'
import AIImageIntro from '@assets/video/intro-ai-image.mp4'
import { selectUserIsPremium } from '@/store/slices/user/selectors'
import { useSelector } from 'react-redux'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { KIND } from 'baseui/button'
import { useAppDispatch } from '@/store/store'
import { setOpenModalTryPremium } from '@/store/slices/user/actions'
import { StatefulPopover } from 'baseui/popover'
import VideoIntroAIImage from '@assets/video/VideoIntroAIImage.mp4'
import VideoIntroAIImagePlaceHolder from '@assets/images/VideoIntroAIImage_frame_0.jpg'
import { customAmplitude } from '@/utils/customAmplitude'
function AddAIImage() {
  const { popoverActive, setPopoverActive, activePanel } = useAppContext()
  const panelListItem = panelListItems.find(x => x.id === 'ai-image')
  const hasPremium = useSelector(selectUserIsPremium)
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const handleCloseAiImagePanel = () => {
    setPopoverActive(null)
  }
  return (
    <div>
      <StatefulPopover
        content={() => {
          return !popoverActive && !hasPremium ? (
            <div
              style={{
                width: '280px',
                height: hasPremium ? '288px' : '352px',
                background: lightTheme.colors.white,
                borderRadius: '16px',
                overflow: 'hidden',
              }}
            >
              <div className="wrap-videos" style={{ height: '164px' }}>
                <video
                  width="100%"
                  height="100%"
                  style={{
                    objectFit: 'cover',
                  }}
                  loop
                  autoPlay
                  muted
                  playsInline
                  src={AIImageIntro}
                ></video>
              </div>
              <div
                className="wrap-text"
                style={{ padding: '24px', display: 'flex', flexDirection: 'column', gap: '8px' }}
              >
                <h3 style={{ ...lightTheme.typography.Small16Bold, fontWeight: 600 }}>{t('AI Image')}</h3>
                <p
                  style={{
                    ...lightTheme.typography.Small14regular,
                    lineHeight: '22px',
                    color: lightTheme.colors.grayScale600,
                  }}
                >
                  {t('Generate stunning images from your text using AI.')}
                </p>
                {hasPremium ? null : (
                  <div style={{ marginTop: '16px', textAlign: 'right' }}>
                    <ButtonCustom
                      type={SizeButton.STANDARD}
                      kind={KIND.primary}
                      style={{
                        position: 'relative',
                        ...lightTheme.typography.Small14Semibold,
                        color: '#fff',
                        margin: 0,
                        minWidth: '104px',
                        width: 'fit-content',
                      }}
                      onClick={e => {
                        const eventProperties = {
                          Source: 'BtSubscriptionAIArt',
                          Type: 'Standard',
                        }
                        customAmplitude('Premium Prompt', eventProperties)
                        // @ts-ignore
                        window.dataLayer.push({ event: 'premium_prompt', ...eventProperties })
                        dispatch(setOpenModalTryPremium({ isOpen: true, source: 'BtSubscriptionAIArt',
                          callback: () => {
                            setPopoverActive(PopoverType.AI_IMAGE)
                          },
                          video: VideoIntroAIImage,
                          placeholderImage: VideoIntroAIImagePlaceHolder
                        }))
                      }}
                    >
                      {t('Try it now!')}
                    </ButtonCustom>
                  </div>
                )}
              </div>
            </div>
          ) : null
        }}
        triggerType="hover"
        popoverMargin={8}
        placement={'right'}
        overrides={{
          Body: {
            style: ({ $theme }) => ({
              zIndex: '100',
            }),
          },
        }}
      >
        <div>
          <div
            onClick={() => {
              if (!hasPremium) {
                const eventProperties = {
                  Source: 'BtSubscriptionAIArt',
                  Type: 'Standard',
                }
                customAmplitude('Premium Prompt', eventProperties)
                // @ts-ignore
                window.dataLayer.push({ event: 'premium_prompt', ...eventProperties })
                dispatch(setOpenModalTryPremium({ isOpen: true, source: 'BtSubscriptionAIArt',
                  callback: () => {
                    setPopoverActive(PopoverType.AI_IMAGE)
                  },
                  video: VideoIntroAIImage,
                  placeholderImage: VideoIntroAIImagePlaceHolder
                }))
                return
              }
              if (popoverActive === PopoverType.AI_IMAGE) {
                setPopoverActive(null)
              } else {
                setPopoverActive(PopoverType.AI_IMAGE)
              }
            }}
            id="popover-ai-image"
            style={{ position: 'relative' }}
          >
            <PanelListItem
              disable={panelListItem.disable}
              label={panelListItem.name}
              name={panelListItem.name}
              key={panelListItem.name}
              icon={'AIImage'}
              activePanel={activePanel}
              panelSelected={popoverActive === PopoverType.AI_IMAGE}
            />
          </div>
          <AIImage isOpen={popoverActive === PopoverType.AI_IMAGE} handleClosePanel={handleCloseAiImagePanel} />
        </div>
      </StatefulPopover>
    </div>
  )
}

export default AddAIImage
