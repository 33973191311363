import { useEffect, useState } from 'react'
import { ThemeProvider, LightTheme } from 'baseui'
import { useEditorContext } from '@/scenes/engine'
import { ShowModalType } from '@/scenes/engine/common/constants'
import useAppContext from '@/hooks/useAppContext'
import { useTranslation } from 'react-i18next'
import { lightTheme } from '@/customTheme'
import { styled } from 'baseui'
import ButtonCustom from '@/components/ButtonCustom'
import { KIND } from 'baseui/button'
import { SizeButton } from '@/constants/sizeButton'

function SaveProject() {

  const { showModalType, setShowModalType, isOpenPixelManipulationObject, disableEditorState } = useAppContext()
  const { t } = useTranslation()

  const handleSaveProject = () => {
    setShowModalType(ShowModalType.SAVE_PROJECT)
  }

  return (
    <ButtonCustom
      type={SizeButton.LARGE}
      kind={KIND.secondary}
      disabled={isOpenPixelManipulationObject || disableEditorState.disableNavbar}
      onClick={handleSaveProject}
      style={{ 
        display: 'flex',
        gap: '6px',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 16px',
        height: '40px',
        width: 'fit-content',
        borderRadius: '360px',
        cursor: 'pointer',
        marginLeft: '8px',
        ...lightTheme.typography.Small14Semibold,
        transition: 'all 0s',
        background: isOpenPixelManipulationObject || disableEditorState.disableNavbar ? lightTheme.colors.grayScale50 : lightTheme.colors.blackGray,
        ':hover': {
          backgroundColor: lightTheme.colors.grayScale800,
        },
        ':active': {
          backgroundColor: lightTheme.colors.grayScale800,
        },
        pointerEvents: isOpenPixelManipulationObject || disableEditorState.disableNavbar ? 'none' : 'auto'
      }}
    >
      <p
        style={{
          ...lightTheme.typography.Small14Semibold,
          color: isOpenPixelManipulationObject || disableEditorState.disableNavbar ? lightTheme.colors.grayScale200 : lightTheme.colors.white,
        }}
      >
        {t('Save project')}
      </p>
      <span
        style={{
          width: 'fit-content',
          height: '17px',
          borderRadius: '360px',
          fontSize: '10px',
          fontWeight: 700,
          lineHeight: '10px',
          padding: '3.5px 5.64px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: isOpenPixelManipulationObject || disableEditorState.disableNavbar
            ? lightTheme.colors.grayScale200
            : lightTheme.colors.grayScale600,
          color: lightTheme.colors.white,
        }}
      >
        {t('BETA')}
      </span>
    </ButtonCustom>
  )
}

export default SaveProject
