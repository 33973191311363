import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill ? props.fill :"#FF0560"}
      fillRule="evenodd"
      d="m6.251 10.662 5.598-7.016a.844.844 0 0 1 1.164-.142.795.795 0 0 1 .146 1.134l-6.157 7.717a.844.844 0 0 1-1.222.093L2.262 9.233a.794.794 0 0 1-.038-1.143.844.844 0 0 1 1.172-.037l2.855 2.609Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgComponent
