import PanelsList from '../Panels/PanelsList'
import useAppContext from '@/hooks/useAppContext'

function Sidebar({idFromDeeplink, collapse = false}) {
  const { isOpenPixelManipulationObject } = useAppContext()

  return (
    <div
        style={{
          flex: '1 1 0px',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          left: isOpenPixelManipulationObject || collapse ? '-80px' : '16px',
          zIndex: 100,
          filter: 'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.08))',
          background: '#ffffff',
          borderRadius: '12px',
          width: '72px',
          padding: '4px',
          transition: 'left .4s ease-in-out',
          transitionDelay: '0.2s'
        }}
        id="sidebar"
      >
        <PanelsList idFromDeeplink={idFromDeeplink} />
      </div>
  )
}

export default Sidebar