import AutoScroll from '@/components/AutoScroll'
import HeadingInspector from '@/components/HeadingInspector'
import { lightTheme } from '@/customTheme'
import { ThemeProvider } from 'baseui'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TextBox from './AIImageSharedComponent.tsx/TextBox'
import Inspiration from './AIImageSharedComponent.tsx/Inspiration'
import api from '@/services/api'
import { customAmplitude } from '@/utils/customAmplitude'
import { MediaImageRepositoryProcessing } from '@/scenes/engine/objects/media-repository/media_image_repository_processing'
import { nanoid } from 'nanoid'
import { MediaImageRepository } from '@/scenes/engine/objects/media-repository/media_image_repository'
import { MediaImageType } from '@/scenes/engine/objects/media-repository/media_image_type'
import { useEditorContext } from '@/scenes/engine'
import { ObjectType, ShowModalType } from '@/scenes/engine/common/constants'
import AsyncImageGrid from '@/components/AsyncImageGrid'
import useAppContext from '@/hooks/useAppContext'
import { selectTemplateDetails } from '@/store/slices/templates/selectors'
import { useSelector } from 'react-redux'
import CanvasImageRenderer from '@/scenes/engine/utils/canvasImageRenderer'
import { selectImageElements } from '@/store/slices/imageElement/selectors'

function AIImage({ isOpen, handleClosePanel }) {
  const [isBoxShadow, setIsBoxShadow] = useState(false)
  const listSectionRef = useRef(null)
  const [prompt, setPrompt] = useState('')
  const [isDefaultValue, setIsDefaultValue] = useState(true)
  const [queryValue, setQueryValue] = useState(null)
  const [isLoadingSuggestImages, setIsLoadingSuggestImages] = useState(false)

  let imageProcessing = new MediaImageRepositoryProcessing()
  const [positionDefault, setPositionDefault] = useState(0)
  const { editor, activeObject } = useEditorContext()
  const { isReplacingImage, setIsReplacingImage, setRemoveBgSuccess, setDisableEditor, setRemoveBgAbortController, setShowModalType} = useAppContext()
  const templateDetails = useSelector(selectTemplateDetails)
  const filterPacks = useSelector(selectImageElements)

  const fetchSingleImage = async (): Promise<string> => {
    try {
      const result = await api.aiImage(`${prompt} ${queryValue.selectedStyle}`, queryValue.selectedRatio.size.width, queryValue.selectedRatio.size.height)

      return result;
    } catch (error) {
      setShowModalType(ShowModalType.AI_IMAGE_ERROR)
      console.error('Error fetching aiImage:', error);
      throw error;
    }
  };

  const removeBg = async resizedImage => {
    setRemoveBgSuccess(false)
    setDisableEditor(true)
    let abortController = new AbortController()
    setRemoveBgAbortController(abortController)

    setTimeout(async () => {
      await editor.handlers.objectsHandler.removeBg(null, abortController.signal, resizedImage)
      setTimeout(() => {
        setRemoveBgSuccess(true)
      }, 100)
    }, 100)
  }

  const handleImageSelect = async (image: string) => {
    let resizedImage = await imageProcessing.resizeBlobToMaxEdgeSize(image, 1280)
    if(isReplacingImage) {
      setIsReplacingImage(false)
      const replaceLayer = activeObject ? activeObject : editor.handlers.objectsHandler.replaceRefLayer
      const isMagicTemplate = templateDetails?.is_magic
      let isMagicLyaer = isMagicTemplate && replaceLayer.isTemplateLayer
      editor.handlers.transactionHandler.save()
      await editor.handlers.objectsHandler.replaceImage(resizedImage, isMagicLyaer, false)

      await CanvasImageRenderer.getInstance().render(
        editor.handlers.canvasHandler.canvas.getActiveObject(),
        editor.handlers.frameHandler.getSize(),
        filterPacks
      )
      
      if (isMagicLyaer) {
        removeBg(resizedImage)
      }
      const eventProperties = {
        Tool: 'bazaart.replace',
        Type: ObjectType.BAZAART_IMAGE,
      }
      customAmplitude('Selected tool', eventProperties)
      handleClosePanel()
      return
    }
    await addImageToCanvas(image);
  };

  const addImageToCanvas = async url => {
    let resizedImage = await imageProcessing.resizeBlobToMaxEdgeSize(url, 1280)
    let guid = nanoid()
    let assetStateId = nanoid()

    let maskInfo = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.extractMask(resizedImage)

    await MediaImageRepository.getInstance().storeImageBlobString(
      guid,
      assetStateId,
      MediaImageType.latest,
      resizedImage
    )
    await MediaImageRepository.getInstance().storeImageBlobString(
      guid,
      assetStateId,
      MediaImageType.original,
      resizedImage
    )
    await MediaImageRepository.getInstance().storeImageBlobString(
      guid,
      assetStateId,
      MediaImageType.mask,
      maskInfo.blob
    )

    let frame = editor.handlers.frameHandler.get()
    let layerSize = maskInfo.size
    let canvasAspectRatio = frame.width / frame.height
    let layerAspectRatio = layerSize.width / layerSize.height
    let width = 0.6

    if (layerAspectRatio < canvasAspectRatio) {
      width = (width * layerAspectRatio) / canvasAspectRatio
    }

    let nextPosition = positionDefault + 30 > 120 ? 0 : positionDefault + 30;
    setPositionDefault(nextPosition)

    const object = {
      type: ObjectType.BAZAART_IMAGE,
      centerPoint: {
        x: 0.5,
        y: 0.5,
      },
      sizeOnCanvas: {
        width: width,
      },
      transformation: {
        horizontalFlip: false,
        verticalFlip: false,
      },
      boundingBox: { y: 0, width: 1, height: 1, x: 0 },
      absoluteRotation: 0,
      bazaartGuid: guid,
      layerAssetStateId: assetStateId,
      hasTransparency: maskInfo.hasTransparency,
      isStockImage: true,
      offsetX: positionDefault,
      offsetY: positionDefault,
    }
    editor.handlers.objectsHandler.add(object)
    customAmplitude('Selected Tool', {
      Tool: 'bazaart.add.ai'
    })
  }

  const { t } = useTranslation()
  return (
    <ThemeProvider theme={lightTheme}>
      <div
        id="popover-ai-image"
        style={{
          overflow: 'hidden',
          boxSizing: 'border-box',
          position: 'fixed',
          top: 'calc(-50vh + 48px + 216px)',
          left: '80px',
          background: '#ffffff',
          width: '340px',
          height: 'calc(100vh - 96px)',
          flex: 'none',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
          borderRadius: '16px',
          display: isOpen ? 'flex' : 'none',
          flexDirection: 'column',
        }}
      >
        <div style={{ boxShadow: isBoxShadow ? '0px 0px 6px rgba(0, 0, 0, 0.1)' : 'none' }}>
          <HeadingInspector
            hasBoxShadow={false}
            hasNavigation={false}
            title={t('AI Image')}
            hasClose={true}
            handleClose={() => handleClosePanel()}
          ></HeadingInspector>
        </div>
        <AutoScroll
          style={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            paddingBottom: '20px',
            position: 'relative',
            paddingTop: '0px',
          }}
          ref={listSectionRef}
          handleScroll={async e => {
            if (e.target.scrollTop > 0) {
              setIsBoxShadow(true)
            } else {
              setIsBoxShadow(false)
            }
          }}
        >
          <TextBox
            prompt={prompt}
            setPrompt={setPrompt}
            queryValue={queryValue}
            setQueryValue={setQueryValue}
            isLoadingSuggestImages={isLoadingSuggestImages}
            isDefaultValue={isDefaultValue}
            setIsDefaultValue={setIsDefaultValue}
          />
          {
            queryValue ?
              <AsyncImageGrid
                key={queryValue.prompt + queryValue.selectedStyle + queryValue.selectedRatio.id}
                onFetchImages={fetchSingleImage}
                batchSize={4}
                aspectRatio={queryValue.selectedRatio.size.width / queryValue.selectedRatio.size.height}
                loadingPlaceholderSrc={null}
                selectedImage={""}
                onImageSelect={handleImageSelect}
                onLoadingChange={setIsLoadingSuggestImages}
                disableCreateMore={!prompt.length || !isDefaultValue}
                wrapScrollRef={listSectionRef}
              />
              :
              <Inspiration setPrompt={setPrompt} />
          }

        </AutoScroll>
      </div>
    </ThemeProvider>
  )
}

export default AIImage


