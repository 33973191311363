import { useStyletron } from 'baseui'
import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { getResizeUrl } from '@/utils/getResizeUrl'
import { useAppDispatch } from '@/store/store';
import { customAmplitude } from '@/utils/customAmplitude';
import { setOpenModalTryPremium } from '@/store/slices/user/actions';
import VideoIntroMagicBg from '@assets/video/intro-magic-bg.mp4'
import Icons from '../../../Icons';


interface WrapImageProps {
  srcImage: URL;
  resize?: [number, number] | null;
  hasHoverState?: boolean;
  suggestImageLoaded?: boolean
  withCrown?: boolean
}

function WrapImage({srcImage, resize = null, hasHoverState = true, suggestImageLoaded = true , withCrown = false}: WrapImageProps) {
  const [css] = useStyletron()
  const dispatch = useAppDispatch()
  return (
    <div
      className={css({
        position: 'relative',
        width: '100%',
        height: '100%',
        ':hover div': {
          display: hasHoverState ? 'block !important' : 'none',
        },
        borderRadius: '6px',
        overflow: 'hidden',
        pointerEvents: !suggestImageLoaded ? 'none' : srcImage ? 'auto' : 'none'
      })}
    >
      {srcImage ? (
        <LazyLoadImage
          width={'100%'}
          height={'100%'}
          src={resize ? getResizeUrl({ size: `${resize[0]}x${resize[1]}`, url: srcImage }).toString() : srcImage.toString()}
          alt=""
          style={{ objectFit: 'fill' }}
        />
      ) : null}
      <div
        className="overlay"
        style={{
          position: 'absolute',
          inset: 0,
          background: 'rgba(0,0,0,0.2)',
          display: 'none',
        }}
      ></div>
      {withCrown ? (
        <div style={{ position: 'absolute', top: '4px', right: '4px' }}>
          <Icons.PremiumIcon />
        </div>
      ) : null}
    </div>
  )
}

export default WrapImage
