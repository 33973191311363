import { fabric } from 'fabric'
import { ObjectType, PROPERTIES_TO_EXPORT } from '../common/constants'
import { loadImageFromURL } from './image-loader'
import isNaN from 'lodash/isNaN'
import './fabricjs-custom-filters/filter-flexblur'
import { MediaImageRepository } from '@scenes/engine/objects/media-repository/media_image_repository'
import { allMediaImageTypeValues, MediaImageType } from '@scenes/engine/objects/media-repository/media_image_type'
import { Rectangle } from '@scenes/engine/objects/media-repository/rectangle'
import { Size } from '@scenes/engine/objects/media-repository/size'
import { MediaImageRepositoryProcessing } from '@scenes/engine/objects/media-repository/media_image_repository_processing'
import { nanoid } from 'nanoid'
import * as PIXI from 'pixi.js'
import { getFabricFontMetrics, isColorBright, normalize } from './utils'
import { lightTheme } from '@/customTheme'
import CanvasImageRenderer from './canvasImageRenderer'
import { CanvasLayerShadowEffect } from '@/interfaces/CanvasLayerShadowEffect'
import { CanvasLayerOutlineEffect } from '@/interfaces/CanvasLayerOutlineEffect'
import { Point } from '../objects/media-repository/point'
import { isUndefined } from 'mathjs'

const textAligns = ['left', 'center', 'right', 'justify', 'justify-center']
const textAlignsNSFormat = ['left', 'right', 'center']

const blendMap = {
  hardLightBlendMode: 'hard-light',
  softLightBlendMode: 'soft-light',

  '': 'normal',
  multiplyBlendMode: 'multiply',
  screenBlendMode: 'screen',
  overlayBlendMode: 'overlay',
  darkenBlendMode: 'darken',
  lightenBlendMode: 'lighten',
  colorDodgeBlendMode: 'color-dodge',
  colorBurnBlendMode: 'color-burn',
  differenceBlendMode: 'difference',
  exclusionBlendMode: 'exclusion',
}
class ObjectToFabric {
  
  async cloneImage(element: fabric.StaticImage) {
    return new Promise(function (resovle, reject) {
      element.clone(cloned => {
        resovle(cloned)
      })
    })
  }

  async run(item, options) {
    let object
    item.type = item.type ? item.type : item.itemType
    switch (item.type) {
      case ObjectType.STATIC_VECTOR:
        object = await this[ObjectType.STATIC_VECTOR](item, options)
        break
      case ObjectType.STATIC_PATH:
        object = await this[ObjectType.STATIC_PATH](item, options)
        break
      case ObjectType.BAZAART_STICKER:
      case ObjectType.BAZAART_IMAGE:
      case ObjectType.BAZAART_SHAP:
      case ObjectType.BAZAART_DRAWING:
      case ObjectType.STATIC_IMAGE:
        object = await this[ObjectType.BAZAART_STICKER](item, options)
        break
      case ObjectType.BAZAART_TEXT:
      case ObjectType.STATIC_TEXT:
        object = await this[ObjectType.BAZAART_TEXT](item, options)
        break
      case ObjectType.BAZAART_BG:
      case ObjectType.BACKGROUND_IMAGE:
        object = await this[ObjectType.BAZAART_BG](item, options)
        break
      case ObjectType.BAZAART_OVERLAY:
        object = await this[ObjectType.BAZAART_OVERLAY](item, options)
        break
      case ObjectType.GROUP:
        object = await this[ObjectType.GROUP](item, options)
        break
    }
    return object
  }

  [ObjectType.STATIC_IMAGE](item, options) {
    return new Promise(async (resolve, reject) => {
      try {
        const baseOptions = this.getBaseOptions(item, options)
        const src = item.metadata.src
        const image: any = await loadImageFromURL(src)

        const { width, height } = baseOptions
        if (!width || !height) {
          baseOptions.width = image.width
          baseOptions.height = image.height
        }

        const element = new fabric.StaticImage(image, {
          ...baseOptions,
          cropX: item.metadata.cropX || 0,
          cropY: item.metadata.cropY || 0,
        })

        const { top, left } = element

        if (isNaN(top) || isNaN(left)) {
          element.set({
            top: options.top,
            left: options.left,
          })
          element.scaleToWidth(480)
        }
        resolve(element)
      } catch (err) {
        reject(err)
      }
    })
  }

  [ObjectType.BAZAART_SHAP](item, options) {
    return new Promise(async (resolve, reject) => {
      try {
        let object = (await this[ObjectType.BAZAART_STICKER](item, options)) as fabric.StaticShap
        // object = object as fabric.StaticImage
        // debugger;
        // object.type = fabric.StaticShap.type
        resolve(object)
      } catch (err) {
        reject(err)
      }
    })
  }

  rotateAroundPointInsideObject(object, offsetX, offsetY, rotationAngle) {
      var pivotPoint = new fabric.Point(offsetX, offsetY); // Custom pivot point coordinates
      // Get the object's current center point
      var objectCenter = object.getCenterPoint();

      // Calculate the vector from the pivot point to the object's center
      var vectorToCenter = objectCenter.subtract(pivotPoint);

      // Rotate this vector by the desired angle
      var radians = fabric.util.degreesToRadians(rotationAngle);
      var cos = Math.cos(radians);
      var sin = Math.sin(radians);

      var rotatedVectorX = vectorToCenter.x * cos - vectorToCenter.y * sin;
      var rotatedVectorY = vectorToCenter.x * sin + vectorToCenter.y * cos;
      var rotatedVector = new fabric.Point(rotatedVectorX, rotatedVectorY);

      // Calculate the new center point after rotation
      var newCenter = pivotPoint.add(rotatedVector);

      // Update the object's position and rotation
      object.set({
        left: newCenter.x,
        top: newCenter.y,
        angle: object.angle + rotationAngle
      });
  }


  [ObjectType.BAZAART_STICKER](item, options) {    
    return new Promise(async (resolve, reject) => {
      try {
        let img: any = null
        const optionsAPI = this.getOptionsAPI(item,options)
        let latestImageUrl = await MediaImageRepository.getInstance().getImage(
          item.bazaartGuid,
          item.layerAssetStateId,
          MediaImageType.latest
        )

        let thumbnailImageUrl = item.loadThumbnail === false ? null : await MediaImageRepository.getInstance().getImage(
          item.bazaartGuid,
          item.layerAssetStateId,
          MediaImageType.thumbnail
        )

        // TODO: this is a temporary solution to differentiate between latest image loading of saved projects
        // and graphics image loading of new project as well as templates
        if (item.isLatest) {
          thumbnailImageUrl = null;
        }
        
        img = await loadImageFromURL(thumbnailImageUrl ? thumbnailImageUrl : latestImageUrl)
        
        const sizeOnCanvas = new Size(options.width, options.height);
        let shadowEffects = new CanvasLayerShadowEffect();
        let inputBounds = new Rectangle(0, 0, img.width, img.height);
        let shadowRect = shadowEffects.revertEffectBounds(item, sizeOnCanvas, false, inputBounds);
        let nonSymmetricOffset = new Point(shadowRect.x, shadowRect.y) ;
        
        let outlineEffects = new CanvasLayerOutlineEffect();
        let outlineRect = outlineEffects.revertEffectBounds(item, sizeOnCanvas, false, shadowRect);
        
        let imageTargetWidthOnCanvas = item.sizeOnCanvas.width * options.width
        let imageTargetHeightOnCanvas = (imageTargetWidthOnCanvas / outlineRect.width) * outlineRect.height

        let imageScaleX = item.scaleX ? item.scaleX : imageTargetWidthOnCanvas / outlineRect.width
        let imageScaleY = item.scaleY ? item.scaleY : imageTargetHeightOnCanvas / outlineRect.height
        
        let boundingBox = new Rectangle(
          item.boundingBox.x,
          item.boundingBox.y,
          item.boundingBox.width,
          item.boundingBox.height
        )
        
        let leftOpt = item.left ? item.left : options.left + options.width * item.centerPoint.x + nonSymmetricOffset.x * imageScaleX
        let topOpt = item.top ? item.top : options.top + options.height * item.centerPoint.y + nonSymmetricOffset.y * imageScaleY

        if(!isUndefined(item.offsetX)) {
          leftOpt += item.offsetX
        }
        if(!isUndefined(item.offsetY)) {
          topOpt += item.offsetY
        }

        let baseOptions = {
          ...optionsAPI,
          top: topOpt,
          left: leftOpt,
          width: img.width,
          height: img.height,
          scaleX: imageScaleX,
          scaleY: imageScaleY,
          originX: 'center',
          originY: 'center',
          cropX: 0,
          cropY: 0,
          boundingBox: boundingBox,
          sizeOnCanvas: item.sizeOnCanvas,
          latest: item.latest,
          isLatest: item.isLatest,
          flipX: item.flipX !== null && item.flipX !== undefined ? item.flipX : item.transformation.horizontalFlip,
          flipY: item.flipY !== null && item.flipY !== undefined ? item.flipY : item.transformation.verticalFlip,
          mask: item.mask,
          effects_from_template: item.effects ?? {},
          adjustments_from_template: item.adjustments ?? {},
          effects: item.effects ?? {
            hasFillImage: false,
          },
          id: item.bazaartGuid,
          type: item.type,
          hasTransparency: item.hasTransparency !== undefined ? item.hasTransparency : true,
          opacity: item.opacity ? item.opacity : 1,
          filters: item.filters ? item.filters : [],
          zoomX: item.zoomX ? item.zoomX : undefined,
          zoomY: item.zoomY ? item.zoomY : undefined,
          globalCompositeOperation: item.globalCompositeOperation ? item.globalCompositeOperation : undefined,
          selected: false,
          evented: true,
          useBzrtBgMask:item.useBzrtBgMask,
          layerAssetStateId: item.layerAssetStateId,
          transformation: {
            horizontalFlip:item.transformation.horizontalFlip,
            verticalFlip:item.transformation.verticalFlip,
            kDistort:item?.transformation?.kDistort,
          },
          centeredRotation: true,
          fullImgUrl:item?.fullImgUrl,
          hasPeople:item?.hasPeople,
          shouldUseMattedImage:item?.shouldUseMattedImage,
          sourceGraphicsItemName:item?.sourceGraphicsItemName,
          sourceGraphicsPackName:item?.sourceGraphicsPackName,
          sourceGraphicsType:item?.sourceGraphicsType,
          features:item?.features,
          videoSpeed:item?.videoSpeed,
          saliencyResults:item?.saliencyResults,
          isTemplateLayer: item.isTemplateLayer,
          filter:item.filter,
          filterIntensity:item.filterIntensity ? item.filterIntensity : 1,
          isStockImage: item.isStockImage,
          isMagicBackgroundLayer: item?.isMagicBackgroundLayer,
          isIntializedNormalizeMask: item.isIntializedNormalizeMask ? item?.isIntializedNormalizeMask : true,
          isIntializedNormalizedImage: item.isIntializedNormalizedImage ? item?.isIntializedNormalizedImage : true,
          // direction: 1, shadow: { color: 'blue', offsetX:20,offsetY:20,blur:30 }
        }
        if(item.type !== ObjectType.BAZAART_SHAP) {
          const now = new Date().toISOString()
          baseOptions = {
            ...baseOptions,
            //TODO: Hardcoded the default value
            // @ts-ignore
            isLiftBackgroundLayer: item.isLiftBackgroundLayer ? item.isLiftBackgroundLayer : false,
            audioVolume: item.audioVolume ?? 0,
            currentPrice: item.currentPrice ?? 0,
            features: item.features ?? [],
            hasFaceFeatures: item.hasFaceFeatures ?? false,
            hasPeople: item.hasPeople ?? 0,
            imagesFormat: item.imagesFormat ?? {},
            imagesLastUpdated: item.imagesLastUpdated ?? {},
            imagesOrientation: item.imagesOrientation ?? {},
            adjustments: item.adjustments ?? {},
            imageWidth: item.imageWidth ?? 0,
            isDeleted: item.isDeleted ?? false,
            isDirty: item.isDirty ?? false,
            isHidden: item.isHidden ?? false,
            isInitializing: item.isInitializing ?? false,
            isLocked: item.isLocked ?? false,
            isMagicBackgroundLayer: item.isMagicBackgroundLayer ?? false,
            isPlaceholder: item.isPlaceholder ?? false,
            isShopable: item.isShopable ?? false,
            isTemplateLayer: item.isTemplateLayer ?? false,
            itemType: item.type,
            lastPrice: item.lastPrice ?? 0,
            shouldUseMattedImage: item.shouldUseMattedImage ?? false,
            sourceGraphicsType: item.sourceGraphicsType ?? "Photos",
            state: item.state ?? 0,
            storeId: item.storeId ?? 0,
            thumbnailHeight: item.thumbnailHeight ?? 0,
            thumbnailWidth: item.thumbnailWidth ?? 0,
            version: item.version ?? 1,
            videoSpeed: item.videoSpeed ?? 1,
            zLayer: item.zLayer ?? 0
          }
        }
        
        const element = new fabric.StaticImage(img, baseOptions)
        const degrees = (item.angle || item.angle === 0) ? item.angle : fabric.util.radiansToDegrees(item.absoluteRotation);
        
        let rotationPointX = element.left - nonSymmetricOffset.x * imageScaleX;
        let rotationPointY = element.top - nonSymmetricOffset.y * imageScaleY;
        this.rotateAroundPointInsideObject(element, rotationPointX, rotationPointY, degrees)
        
        const { top, left } = element

        if (isNaN(top) || isNaN(left)) {
          element.set({
            top: options.top,
            left: options.left,
          })
          element.scaleToWidth(480)
        }

        if (item.blending) {
          element.globalCompositeOperation = blendMap[item.blending]
        }

        if (thumbnailImageUrl) {
          loadImageFromURL(latestImageUrl).then(async (img: any) => {
            console.log('latest Loaded')
            //@ts-ignore
            element.setElement(img)

            let imageWidth = item.sizeOnCanvas.width * options.width
            let imageHeight = (imageWidth / img.width) * img.height

            let thickness = item.effects?.outline?.thickness;
            if (thickness) {
              let adjustedSize = thickness * Math.max(imageWidth, imageHeight);
              imageWidth += adjustedSize;
              imageHeight += adjustedSize;
            }
        
        
            // let imageScaleX = item.scaleX ? item.scaleX : imageWidth / img.width
            // let imageScaleY = item.scaleY ? item.scaleY : imageHeight / img.height

            // element.scaleX = imageScaleX
            // element.scaleY = imageScaleY
            
            element.set({
              width: img.width,
              height: img.height,
              scaleX: imageWidth / img.width,
              scaleY: imageHeight / img.height,
              // @ts-ignore
              _originalScaleX: imageWidth / img.width,
              _originalScaleY: imageHeight / img.height,
            })
            element.setCoords()
          })
        }

        resolve(element)
      } catch (err) {
        reject(err)
      }
    })
  }

  calculateFontSizeToFitWidth(text, fontFamily, targetWidth, tolerance = 1) {
      // Set an initial font size
      const initialFontSize = 10;
      const calcMaxLineWidth = (line: string): number => {
        // Use fabric.Text for single-line and fabric.Textbox for multi-line
        const tempTextObject = new fabric.Text(line, {
          fontFamily: fontFamily,
          fontSize: initialFontSize
        })
  
        // Measure the width at the initial font size
        const initialWidth = tempTextObject.width;
  
        // Calculate the scaling factor to fit the target width
        const scaleFactor = targetWidth / initialWidth;
        const calculatedFontSize = initialFontSize * scaleFactor / 1.01;
        return calculatedFontSize;
      }
      
      // // apparently fabric treats newline as a space as well
      let lines = text.split('\n');
      // for (let lineIdx = 0; lineIdx < lines.length -1; lineIdx++){
      //   lines[lineIdx] += '';
      // }

      let linesWidth = lines.map(l => calcMaxLineWidth(l))
      let minLineFontWidth = Math.min(...linesWidth);
      
      return minLineFontWidth;
  }

  private extractTextStroke(item: any, maxEdge: number): {strokeColor?: string, strokeWidth?: number} {
    if (!item.effects.outline) {
      return {strokeColor: null, strokeWidth: null};
    }
    let retStroke = {
        strokeColor: item.stroke ? item.stroke : item.effects.outline.color,
        strokeWidth: item.strokeWidth ? item.strokeWidth : item.effects.outline.thickness * maxEdge
    }
    return retStroke;
  }

  private extractTextShadow(item: any, maxEdge: number): fabric.Shadow | null {
    if (!item.effects.shadow) {
      return null;
    }
    let shadowProps = item.effects.shadow
    const colorNumber = new PIXI.Color(shadowProps.color).toUint8RgbArray()
    let retShadow = item.shadow 
      ? item.shadow
      : {
          color: `rgba(${colorNumber[0]}, ${colorNumber[1]}, ${colorNumber[2]}, ${shadowProps.opacity})`,
          distance: shadowProps.distance,
          angle: shadowProps.angle,
          offsetX: Math.cos(shadowProps.angle) * shadowProps.distance * maxEdge,
          offsetY: Math.sin(shadowProps.angle) * shadowProps.distance * maxEdge,
          blur: Math.floor(shadowProps.blur * maxEdge),
        }
    return retShadow;
  }

  private extractLetterSpacing(item: any, textAttr: any): number {
    if (!textAttr.NSKern) {
      return 0;
    }
    let fontSize = textAttr.NSOriginalFont?.size ?? textAttr.NSFont?.size;
    let letterSpacing = (textAttr.NSKern / fontSize)
    return letterSpacing * 1000;
  }

  private extractLineHeight(fontLineHeight: number, textAttr: any): number {
    let presetLineHeight = fabric.Text.prototype._fontSizeMult;
    if (textAttr.NSParagraphStyle.NSMinLineHeight) {
      let fontSize = textAttr.NSOriginalFont?.size ?? textAttr.NSFont?.size;
      let lineAddition = textAttr.NSParagraphStyle.NSMinLineHeight / fontSize / presetLineHeight;
      return lineAddition;
    } else  {
      return 1 / presetLineHeight
    }
  }

  private extractFontSize(item: any, textAttr: any, textOptions: any, itemWidth: number): number {
    let calcFontSize = 10;
    let fontRelativeToWidth = item.textProperties.imageToTextTransformation?.fontRelativeToWidth
    if (fontRelativeToWidth && fontRelativeToWidth !== -1) {
      calcFontSize = itemWidth * item.textProperties.imageToTextTransformation.fontRelativeToWidth
      return Math.floor(calcFontSize);
    }

    if (!item.textProperties.imageToTextTransformation || item.textProperties.imageToTextTransformation.fontRelativeToWidth === -1) {
      let fontSize = this.calculateFontSizeToFitWidth(textOptions.text, textOptions.fontFamily, itemWidth);
      return fontSize
    }
    
    return calcFontSize;
  }

  private extractTextColors(item: any, textAttr: any): {fill: string | null, backgroundColor: string | null }{
    let fill = textAttr.NSColor;
    let backgroundColor = null;

    if(item.backgroundColor){
      backgroundColor = item.backgroundColor
    }else if(item.textProperties.presentBackground){
      backgroundColor = item.textProperties.textColorHex 
    }
    if(backgroundColor){
      fill = isColorBright(backgroundColor) ? lightTheme.colors.blackGray : '#ffffff'
    }

    if (item.effects.kOverlayColorObject) {
      fill = fill ?? item.effects.kOverlayColorObject.color;
    }
    return {fill: fill, backgroundColor: backgroundColor }
  }

  [ObjectType.BAZAART_TEXT](item, options) {
    return new Promise(async (resolve, reject) => {
      try {
        let relativeWidth = item.textProperties.imageToTextTransformation?.sizeRelativeToWidth?.width ?? 1;
        // some edge case where for some reason the information was uploaded wrong
        relativeWidth = relativeWidth == 0 ? 1 : relativeWidth;
        let actual_w = relativeWidth * item.sizeOnCanvas.width * options.width
      
        let relativeHeight = item.textProperties.imageToTextTransformation?.sizeRelativeToWidth?.height ?? 1;
        // some edge case where for some reason the information was uploaded wrong
        relativeHeight = relativeHeight == 0 ? 1 : relativeHeight;
        let actual_h = relativeHeight * item.sizeOnCanvas.height * options.height

        let renderSizeOnCanvas_w = item.sizeOnCanvas.width * options.width
  
        const optionsAPI = this.getOptionsAPI(item,options)

        let baseOptions: any = {
          ...optionsAPI,
          angle: (item.angle || item.angle === 0) ? item.angle : fabric.util.radiansToDegrees(item.absoluteRotation),

          width: item.arcAngle ? actual_w : item.width ? item.width : actual_w,
          height: item.arcAngle ? actual_h : item.height ? item.height : actual_h,
          originX: 'center',
          originY: 'center',
          scaleX: 1,
          scaleY: 1,
          fill: lightTheme.colors.blackGray,
          metadata: {},
          flipX: item.flipX ? item.flipX : item.transformation.horizontalFlip,
          flipY: item.flipY ? item.flipY : item.transformation.verticalFlip,
          transformation:item.transformation,
          opacity: item.opacity ? item.opacity : 1,
          type: item.type,
          id: item.id ? item.id : nanoid(),
          bazaartGuid: item.bazaartGuid,
          selected: false,
          features:item?.features,
          paintFirst: 'stroke',
          fontStyle: 'normal',
          strokeLineJoin: 'round',
          strokeLineCap: 'round',
          itemType: item.itemType ? item.itemType : item.type,
          isResized: !!item.isResized,
          splitByGrapheme: !!item.splitByGrapheme
        }

        const textAttr = item.textProperties.attributedText.runs[0].attributes
        const textAlign = item.textAlign
        ? item.textAlign
        : textAttr.NSParagraphStyle.NSAlignment
              ? textAlignsNSFormat[textAttr.NSParagraphStyle.NSAlignment]
              : textAligns[item.textProperties.alignment]
        const textOptions = {
          ...baseOptions,
          text: item.text
            ? item.text
            : item.textProperties.attributedText.string
            ? item.textProperties.attributedText.string
            : 'Default Text',
          textProperties: item.textProperties,
          fontFamily: item.fontFamily ? item.fontFamily : textAttr.NSFont.systemName,
          fill: item.fill ? item.fill : textAttr.NSColor,
          textAlign: textAlign ?? 'center', // default to center
          sizeOnCanvas: item.sizeOnCanvas,
          transformation: item.transformation,
          effects: item.effects,
        }

        
        textOptions.arcAngle = item.arcAngle ? item.arcAngle : item.textProperties.arcAngle
        textOptions.fontSize = this.extractFontSize(item, textAttr, textOptions, renderSizeOnCanvas_w);
        let metrics = getFabricFontMetrics(textOptions.fontSize, textOptions.fontFamily, textOptions.text);
        textOptions.lineHeight = this.extractLineHeight(metrics.lineHeightRatio, textAttr) // (metrics.lineHeightRatio / 1.13);
        textOptions.charSpacing = this.extractLetterSpacing(item, textAttr);
        
        let maxEdge = Math.max(actual_w, actual_h);
        textOptions.shadow = this.extractTextShadow(item, maxEdge);
        let {strokeColor, strokeWidth} = this.extractTextStroke(item, maxEdge);
        textOptions.stroke = strokeColor;
        textOptions.strokeWidth = strokeWidth;
        
        let {fill, backgroundColor} = this.extractTextColors(item, textAttr);
        textOptions.fill = fill;
        textOptions.backgroundColor = backgroundColor;
        

        let fontOffsetX = 0//(item.textProperties.imageToTextTransformation?.originRelativeToWidth?.x ?? 0) * renderSizeOnCanvas_w;
        let fontOffsetY = 0//(item.textProperties.imageToTextTransformation?.originRelativeToWidth?.y ?? 0) * renderSizeOnCanvas_w;

        textOptions.top = item.top ? item.top : options.top + options.height * (item.centerPoint.y) + fontOffsetY;
        textOptions.left = item.left ? item.left : options.left + options.width * (item.centerPoint.x) + fontOffsetX;
        
        // textOptions.fontSize
        const element = new fabric.StaticText(textOptions)
        // element._fontSizeMult = 

        const { top, left, width, height } = element
        // @ts-ignore
        element._originalHeight = element.height
        // @ts-ignore
        element._originalWidth = element.width

        if (isNaN(top) || isNaN(left)) {
          element.set({
            top: options.top + options.height / 2 - height / 2,
            left: options.left + options.width / 2 - width / 2,
          })
        }
        
        resolve(element)
      } catch (err) {
        reject(err)
      }
    })
  }

  [ObjectType.BAZAART_BG](item, options) {
    return new Promise(async (resolve, reject) => {
      try {
        let baseOptions: any
        let image: any = null


        let latestImageUrl = await MediaImageRepository.getInstance().getImage(
          item.bazaartGuid,
          item.layerAssetStateId,
          MediaImageType.latest
        )
        let thumbnailImageUrl = item.loadThumbnail === false ? null : await MediaImageRepository.getInstance().getImage(
          item.bazaartGuid,
          item.layerAssetStateId,
          MediaImageType.thumbnail
        )

        let imageWidth = options.width / item.boundingBox.width
        let imageHeight = options.height / item.boundingBox.height

        if (item.version > 32) {
          image = await loadImageFromURL(thumbnailImageUrl ? thumbnailImageUrl : latestImageUrl)
        }

        const topOpt = item.top ? item.top : options.top + options.height * item.centerPoint.y
        const leftOpt = item.left ? item.left : options.left + options.width * item.centerPoint.x
        const optionsAPI = this.getOptionsAPI(item,options)

        baseOptions = {
          ...optionsAPI,
          angle: (item.angle || item.angle === 0) ? item.angle : 0,
          top: topOpt,
          left: leftOpt,
          width: item.version > 32 ? image.width : options.width,
          height: item.version > 32 ? image.height : options.height,
          originX: 'center',
          originY: 'center',
          scaleX: item.scaleX ? item.scaleX : (item.version > 32 ? imageWidth / image.width : 1),
          scaleY: item.scaleY ? item.scaleY : (item.version > 32 ? imageHeight / image.height : 1),
          flipX: false,
          flipY: false,
          skewX: 0,
          skewY: 0,
          effects: item.effects ?? {hasFillImage: false},
          transformation: item.transformation ?? {
            horizontalFlip: false,
            verticalFlip: false,
          },
          filters: item.filters ? item.filters : [],
          metadata: {},
          selectable: false,
          hasControls: false,
          lockMovementY: true,
          lockMovementX: true,
          strokeWidth: 0,
          padding: 0,
          evented: false,
          type: ObjectType.BAZAART_BG,
          id: item.bazaartGuid,
          backgroundIdentifier:item?.backgroundIdentifier,
          layerAssetStateId: item.layerAssetStateId,
          isAnimated:item?.isAnimated,
          isBackgroundImage: item?.isBackgroundImage,
          startTime:item?.startTime,
          _endTime: item?._endTime,
          shouldUseMattedImage: item?.shouldUseMattedImage,
          version: item.version,
          videoMaskBoundingBox: item.videoMaskBoundingBox,
          videoMaskType: item.videoMaskType
        }

        // const { width, height } = baseOptions
        // if (!width || !height) {
        //   baseOptions.width = image.width
        //   baseOptions.height = image.height
        // }

        const element = new fabric.StaticImage(image, {
          ...baseOptions,
          cropX: 0,
          cropY: 0,
          boundingBox: item.boundingBox
        })  

        const { top, left } = element

        if (isNaN(top) || isNaN(left)) {
          element.set({
            top: options.top,
            left: options.left,
          })
          element.scaleToWidth(480)
        }

        if (!thumbnailImageUrl) {
          resolve(element)
          return
        }

        loadImageFromURL(latestImageUrl).then((img: any) => {
          // console.log('latest Loaded')
          //@ts-ignore
          element.setElement(img)
          element.set({
            width: img.width,
            height: img.height,
            scaleX: imageWidth / img.width,
            scaleY: imageHeight / img.height,
            // @ts-ignore
            _originalScaleX: imageWidth / img.width,
            _originalScaleY:imageHeight / img.height,
          })
        })

        resolve(element)
      } catch (err) {
        reject(err)
      }
    })
  }

  [ObjectType.BAZAART_OVERLAY](item, options) {
    return new Promise(async (resolve, reject) => {
      try {
        let baseOptions: any
        let image: any = null
        let latestImageUrl = await MediaImageRepository.getInstance().getImage(
          item.bazaartGuid,
          item.layerAssetStateId,
          MediaImageType.latest
        )
        let thumbnailImageUrl = await MediaImageRepository.getInstance().getImage(
          item.bazaartGuid,
          item.layerAssetStateId,
          MediaImageType.thumbnail
        )
        let imageWidth = options.width / item.boundingBox.width
        let imageHeight = options.height / item.boundingBox.height
        if (item.version <= 32) {
          resolve(null)
          return
        }

        image = await loadImageFromURL(thumbnailImageUrl ? thumbnailImageUrl : latestImageUrl)

        const optionsAPI = this.getOptionsAPI(item,options)

        baseOptions = {
          ...optionsAPI,
          angle: (item.angle || item.angle === 0) ? item.angle : 0,
          top: item.top ? item.top : options.top + options.height * item.centerPoint.y,
          left: item.left ? item.left : options.left + options.width * item.centerPoint.x,
          width: image.width,
          height: image.height,
          originX: 'center',
          originY: 'center',
          scaleX: imageWidth / image.width,
          scaleY: imageHeight / image.height,
          flipX: false,
          flipY: false,
          skewX: 0,
          skewY: 0,
          metadata: {},
          selectable: false,
          hasControls: false,
          lockMovementY: true,
          lockMovementX: true,
          strokeWidth: 0,
          padding: 0,
          evented: false,
          type: ObjectType.BAZAART_OVERLAY,
          id: item.bazaartGuid,
          layerAssetStateId: item.layerAssetStateId,
          blending:item?.blending,
          isAnimated:item?.isAnimated,
          overlayType:item?.overlayType,
          startTime:item?.startTime,
          _endTime:item?._endTime,
        }

        const element = new fabric.StaticImage(image, {
          ...baseOptions,
          boundingBox: item.boundingBox,
          blending: item.blending,
          version: item.version,
          globalCompositeOperation: item.blending ? blendMap[item.blending] : blendMap.screenBlendMode,
          cropX: 0,
          cropY: 0,
        })

        const { top, left } = element
        if (isNaN(top) || isNaN(left)) {
          element.set({
            top: options.top,
            left: options.left,
          })
          element.scaleToWidth(480)
        }
        if (thumbnailImageUrl) {
          loadImageFromURL(latestImageUrl).then((img: any) => {
            console.log('latest Loaded')
            element.setElement(img)
            element.set({
              width: img.width,
              height: img.height,
              scaleX: imageWidth / img.width,
              scaleY: imageHeight / img.height,
              // @ts-ignore
              _originalScaleX: imageWidth / img.width,
              _originalScaleY: imageHeight / img.height,
            })
          })
        }
        resolve(element)
      } catch (err) {
        reject(err)
      }
    })
  }

  [ObjectType.STATIC_PATH](item, options) {
    return new Promise(async (resolve, reject) => {
      try {
        const baseOptions = this.getBaseOptions(item, options)
        const path = item.metadata.value
        const fill = item.metadata.fill
        const element = new fabric.StaticPath({ ...baseOptions, path, fill: fill ? fill : lightTheme.colors.blackGray })

        const { top, left } = element

        if (isNaN(top) || isNaN(left)) {
          element.set({
            top: options.top,
            left: options.left,
          })
          element.scaleToWidth(320)
        }
        resolve(element)
      } catch (err) {
        reject(err)
      }
    })
  }

  [ObjectType.STATIC_VECTOR](item, options) {
    return new Promise(async (resolve, reject) => {
      try {
        const baseOptions = this.getBaseOptions(item, options)
        const src = item.metadata.src
        fabric.loadSVGFromURL(src, (objects, opts) => {
          const { width, height, top, left } = baseOptions
          if (!width || !height) {
            baseOptions.width = opts.width
            baseOptions.height = opts.height
            baseOptions.top = options.top
            baseOptions.left = options.left
          }
          const object = new fabric.StaticVector(objects, opts, { ...baseOptions, src })
          if (isNaN(top) || isNaN(left)) {
            object.set({
              top: options.top,
              left: options.left,
            })
            object.scaleToWidth(320)
          }
          resolve(object)
        })
      } catch (err) {
        reject(err)
      }
    })
  }

  [ObjectType.GROUP](item, options){
    return new Promise(async (resolve, reject) => {
      try {
      
        const baseOptions = (({ left, top, width, height, scaleX, scaleY,angle, opacity, flipX, flipY, skewX, skewY}) =>
         ({ left, top, width, height, scaleX, scaleY,angle, opacity, flipX, flipY, skewX, skewY}))(item);

        let objects: fabric.Object[] = []
        for (const object of item.objects) {
          object.loadThumbnail = item.loadThumbnail
          objects = objects.concat(await this.run(object, options))
        }
        // @ts-ignore
        const element = new fabric.Group(objects, baseOptions)

        resolve(element)
      } catch (err) {
        reject(err)
      }
    })
  }

  getBaseOptions(item, options) {
    const { left, top, width, height, scaleX, scaleY } = item
    let metadata = item.metadata ? item.metadata : {}
    const { fill, angle, originX, originY } = metadata
    let baseOptions = {
      angle: angle ? angle : 0,
      top: options.top + top,
      left: options.left + left,
      width: width,
      height: height,
      originX: originX || 'center',
      originY: originY || 'center',
      scaleX: scaleX || 1,
      scaleY: scaleY || 1,
      fill: fill || lightTheme.colors.blackGray,
      metadata: metadata,
    }

    return baseOptions
  }

  getOptionsAPI(item, options){
    let optionsAPI={}
    for (let prop of PROPERTIES_TO_EXPORT) {
        optionsAPI[prop] = item[prop];
    }
    return optionsAPI
  }
}

export default new ObjectToFabric()
