import { createLightTheme } from 'baseui'
const primitives = {
  primaryFontFamily: 'New-Hero, sans-serif',
  // accent: '#F127E4', // hot pink
  //   accent50: '#FDEDFC',
  //   accent100: '#FCD3F9',
  //   accent200: '#F89FF3',
  //   accent300: '#F45AEA',
  //   accent400: '#F127E4',
  //   accent500: '#B71DAD',
  //   accent600: '#901788',
  //   accent700: '#600F5B',

  primary: '#FF0560', // hot pink
  primary50: '#E50556',
  primary100: '#CC044D',
  primary200: '#F89FF3',
  primary300: '#F45AEA',
  primary400: 'rgba(96, 96, 96, 1)',
  primary500: '#B71DAD',
  primary600: 'rgba(122, 122, 126, 0.15)',
  primary700: 'rgba(122, 122, 126, 0.07)',
  primaryLight100: '#FF0560',
  primaryLight200: '#E50556',
  primaryLight300: '#CC044D',

  // gray
  grayScale0: '#FFFFFF',
  grayScale20: '#F9F9F9',
  grayScale50: '#F2F2F2',
  grayScale100: '#E5E5E5',
  grayScale150: '#D9D9D9',
  grayScale200: '#CCCCCC',
  grayScale300: '#B2B2B2',
  grayScale400: '#999999',
  grayScale500: '#808080',
  grayScale600: '#666666',
  grayScale700: '#4D4D4D',
  grayScale800: '#333333',
  grayScale900: '#1A1A1A',
  grayScale1000: '#000000',
  transparent: 'transparent',
  white: '#FFFFFF',

  // branch color
  blackGray: '#111111',
  purple: '#C700CC',
  orange: '#F8BE49',
  orange2: '#FF7006',
  pink: '#FF0560',

}
const overrides = {
  colors: {
    buttonPrimaryFill: primitives.primaryLight100,
    buttonPrimaryHover: primitives.primaryLight200,
    buttonPrimaryActive: primitives.primaryLight300,
    buttonMinimalFill: primitives.transparent,
    buttonMinimalHover: primitives.grayScale50,
    buttonMinimalActive: primitives.grayScale100,
    buttonSecondaryFill: primitives.grayScale50,
    buttonSecondaryHover: primitives.grayScale100,
    buttonSecondaryActive: primitives.grayScale150,
    menuFillHover: primitives.grayScale50,
    buttonTertiaryHover: primitives.grayScale50,
    buttonTertiarySelectedFill: primitives.grayScale100,
    buttonTertiaryActive: primitives.grayScale100,
    toggleFillChecked: primitives.white,
    toggleTrackFill: primitives.grayScale100,
    contentPrimary: '#666666',
    // input
    inputFill: 'transparent',
    // font button
    buttonFontFill: primitives.transparent,
    buttonFontBorder: primitives.grayScale100,
    buttonFontBorderHover: primitives.transparent,
    buttonFontHover: primitives.grayScale100,

    // gray
    grayScale0: '#FFFFFF',
    grayScale20: '#F9F9F9',
    grayScale50: '#F2F2F2',
    grayScale100: '#E5E5E5',
    grayScale150: '#D9D9D9',
    grayScale200: '#CCCCCC',
    grayScale300: '#B2B2B2',
    grayScale400: '#999999',
    grayScale500: '#808080',
    grayScale600: '#666666',
    grayScale700: '#4D4D4D',
    grayScale800: '#333333',
    grayScale900: '#1A1A1A',
    grayScale1000: '#000000',
    transparent: 'transparent',
    white: '#FFFFFF',

    // branch color
    blackGray: '#111111',
    purple: 'C700CC',
    orange: '#F8BE49',
    orange2: '#FF7006',
    pink: '#FF0560',

    // input
    inputBorderError: primitives.primaryLight200,
    inputBorder: primitives.transparent,
    borderFocus: '#B2B2B2',
    inputFillActive: primitives.white,

    text: {
      text_600_default : primitives.grayScale600,
      text_400_secondary : primitives.grayScale400,
      text_black_title : primitives.blackGray,
      text_red_brand : primitives.pink,
      text_white : primitives.grayScale0,
      text_button_white : primitives.white,
      text_200_disable : primitives.grayScale200,
    }
  },
  borders: {
    buttonBorderRadius: '8px',
    popoverBorderRadius: '16px',
    inputBorderRadius: '6px',
    borderDefault: '1px solid rgba(229, 229, 229, 1)',
  },
  typography: {
    LabelLarge: {
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '24px',
    },
    LabelSmall: {
      fontSize: '11px',
      fontWeight: '400',
      lineHeight: '24px',
    },
    Small10regular: {
      fontSize: '10px',
      fontWeight: '400',
      lineHeight: '24px',
      color: '#999999'
    },
    Small10bold: {
      fontSize: '10px',
      fontWeight: '600',
      lineHeight: '16px',
    },
    Small11medium: {
      fontSize: '11px',
      fontWeight: '500',
      lineHeight: '16px',
    },
    Small12medium: {
      fontSize: '12px',
      fontWeight: '500',
      lineHeight: '24px',
      color: primitives.blackGray,
    },
    Small12regular: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '24px',
    },
    Small14regular: {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '24px',
    },
    Small14Semibold: {
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '24px',
      letterSpacing: '-0.006em',
      
    },
    Small14Bold: {
      fontSize: '14px',
      fontWeight: '700',
      lineHeight: '24px',
      color: '#666666',
    },
    Small16Bold: {
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '24px',
      color: primitives.blackGray,
    },
    Header20semibold: {
      fontSize: '20px',
      fontWeight: '600',
      lineHeight: '24px',
      color: primitives.blackGray,
    },
    Title14Bold: {
      fontSize: '14px',
      fontWeight: '700',
      lineHeight: '24px',
      color: primitives.blackGray,
      fontFamily: 'ES-Rebond-Grotesque-Classic, Rubik, Roboto',
    },
    Header16bold: {
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '24px',
      color: primitives.blackGray,
      fontFamily: 'ES-Rebond-Grotesque-Classic, Rubik, Roboto',
    },
    Header20bold: {
      fontSize: '20px',
      fontWeight: '700',
      lineHeight: '32px',
      color: primitives.blackGray,
      fontFamily: 'ES-Rebond-Grotesque-Classic, Rubik, Roboto',
    },
    Header24bold: {
      fontSize: '24px',
      fontWeight: '800',
      lineHeight: '32px',
      color: primitives.grayScale1000,
      fontFamily: 'ES-Rebond-Grotesque-Classic, Rubik, Roboto',
    },
    Header26bold: {
      fontSize: '26px',
      fontWeight: '700',
      lineHeight: '32px',
      color: primitives.blackGray,
      fontFamily: 'ES-Rebond-Grotesque-Classic, Rubik, Roboto',
    },
    Header28bold: {
      fontSize: '28px',
      fontWeight: '700',
      lineHeight: '24px',
      color: primitives.blackGray,
      fontFamily: 'ES-Rebond-Grotesque-Classic, Rubik, Roboto',
    },
    Title32Bold: {
      fontSize: '32px',
      fontWeight: '800',
      lineHeight: '40px',
      fontFamily: 'ES-Rebond-Grotesque-Classic, Rubik, Roboto',
    },
    Title34Bold: {
      fontSize: '34px',
      fontWeight: '800',
      lineHeight: '40px',
      color: primitives.grayScale1000,
      fontFamily: 'ES-Rebond-Grotesque-Classic, Rubik, Roboto',
    },
    TitleBold: {
      fontSize: '36px',
      fontWeight: '800',
      lineHeight: '40px',
      color: primitives.blackGray,
      fontFamily: 'ES-Rebond-Grotesque-Classic, Rubik, Roboto',
    },
    ParagraphXSmall: {
      fontWeight: '500',
    },
  },
  modal: {
    DialogStyle: ({ $theme, $isOpen, $isVisible }) => ({
      transform: $isOpen && $isVisible ? 'scale(1)' : 'scale(0.9)',
      transitionDuration: $theme.animation.timing200
    }),
    BackdropStyle: {
      background: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'blur(5px)',
    }
  }
}
export const lightTheme = createLightTheme(primitives, overrides)