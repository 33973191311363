import useAppContext from '@/hooks/useAppContext'
import ModalCustom from '@/scenes/Editor/components/Modal/Modal'
import { InspectorPanelType, useInspector } from '@/scenes/Editor/components/Toolbox/InspectorDataProvider'
import { RemoveEditorContext } from '@/scenes/engine'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

function ModalAiImageError() {
  const { showModalType, setShowModalType } = useAppContext()
  const { t } = useTranslation()

  const handleCloseModal = () => {
    setShowModalType(null)
  }

  return (
    <ModalCustom
      isOpen={showModalType === ShowModalType.AI_IMAGE_ERROR}
      onClose={handleCloseModal}
      content={{
        header: t('Oops!') + ' 😬',
        desc: t('Uh-oh! Something went wrong!'),
      }}
      action={{
        primary: {
          name: t('OK'),
          handle: handleCloseModal
        },
      }}
    ></ModalCustom>
  )
}

export default ModalAiImageError