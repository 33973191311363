import { lightTheme } from '@/customTheme'
import { PLACEMENT, Popover } from 'baseui/popover'
import { useTranslation } from 'react-i18next'
import { useStyletron } from 'styletron-react'
import PHOTO_0 from '@/assets/images/thumbnail-style/photo0.png'
import PHOTO_1 from '@/assets/images/thumbnail-style/photo1.png'
import PHOTO_2 from '@/assets/images/thumbnail-style/photo2.png'
import PHOTO_3 from '@/assets/images/thumbnail-style/photo3.png'
import PHOTO_4 from '@/assets/images/thumbnail-style/photo4.png'
import RATIO_1 from '@/assets/images/ratio/ratio1.svg'
import SMALL_RATIO_1 from '@/assets/images/ratio/small-ratio-1.svg'
import RATIO_2 from '@/assets/images/ratio/ratio2.svg'
import SMALL_RATIO_2 from '@/assets/images/ratio/small-ratio-2.svg'
import RATIO_3 from '@/assets/images/ratio/ratio3.svg'
import SMALL_RATIO_3 from '@/assets/images/ratio/small-ratio-3.svg'
import Icons from '../../../Icons'
import { useEffect, useState } from 'react'
import api from '@/services/api'
import DynamicTextarea from './DynamicTextarea'
import { styled } from 'baseui'
import { nsfwManager } from '@/utils/nsfwManager'
import { ShowModalType } from '@/scenes/engine/common/constants'
import useAppContext from '@/hooks/useAppContext'

const STYLES = [
  {
    icon: PHOTO_0,
    text: 'Style',
    id: 0
  },
  {
    icon: PHOTO_1,
    text: 'Photo',
    id: 1
  },
  {
    icon: PHOTO_2,
    text: 'Digital Art',
    id: 2
  },
  {
    icon: PHOTO_3,
    text: 'Watercolor',
    id: 3
  },
  {
    icon: PHOTO_4,
    text: '3D',
    id: 4
  },
]

const RATIOS = [
  {
    icon: RATIO_1,
    smallIcon: SMALL_RATIO_1,
    text: '1:1',
    size: {
      width: 1024,
      height: 1024
    },
    id: 0
  },
  {
    icon: RATIO_2,
    smallIcon: SMALL_RATIO_2,
    text: '16:9',
    size: {
      width: 1024,
      height: 576
    },
    id: 1
  },
  {
    icon: RATIO_3,
    smallIcon: SMALL_RATIO_3,
    text: '9:16',
    size: {
      width: 576,
      height: 1024
    },
    id: 2
  },
]

export default function TextBox({ prompt, setPrompt, queryValue, setQueryValue, isLoadingSuggestImages, isDefaultValue, setIsDefaultValue }) {
  const { t } = useTranslation()
  const [css] = useStyletron()
  const [selectedStyle, setSelectedStyle] = useState(STYLES[0])
  const [selectedRatio, setSelectedRatio] = useState(RATIOS[0])
  const [isOpenRatio, setIsOpenRatio] = useState(false);
  const [isOpenStyle, setIsOpenStyle] = useState(false);
  const { setShowModalType } = useAppContext()

  useEffect(() => {
    if (queryValue) {
      if (queryValue.selectedStyle !== selectedStyle.text) {
        setIsDefaultValue(false)
        return
      }
      if (queryValue.selectedRatio !== selectedRatio) {
        setIsDefaultValue(false)
        return
      }
      if (queryValue.prompt !== prompt) {
        setIsDefaultValue(false)
        return
      }
      setIsDefaultValue(true)
    } else {
      setIsDefaultValue(selectedStyle.id === 0 && selectedRatio.id === 0 && prompt === '')
    }
  }, [selectedStyle, selectedRatio, prompt, queryValue])

  const confirmQueryValue = async () => {
    if(nsfwManager.isSafeText(prompt, "AIImage")) {
      setQueryValue({
        selectedStyle: selectedStyle.text,
        selectedRatio: selectedRatio,
        prompt: prompt
      })
    }
    else {
      setShowModalType(ShowModalType.NSFW_ERROR)
    }
  }


  const ActionButtonsContainer = styled('div', {
    display: 'flex',
    gap: '8px',
    width: '100%',
    paddingRight: '12px'
  });

  const OptionButton = styled('div', {
    height: '32px',
    padding: '4px 12px 4px 8px',
    display: 'flex',
    alignItems: 'center', // Add this to ensure vertical centering
    gap: '4px',
    background: lightTheme.colors.grayScale50,
    borderRadius: '100px',
    cursor: 'pointer',
    // Add these to prevent unwanted growth in Safari
    flexShrink: 0,
    flexBasis: 'auto',
    ':hover': {
      background: lightTheme.colors.grayScale100,
    },
    ':active': {
      background: lightTheme.colors.grayScale100,
    },
  });

  const IconImage = styled('img', {
    width: '24px',
    height: '24px',
    flexShrink: 0,
    objectFit: 'contain'
  });

  const OptionText = styled('span', (props: { $disabled?: boolean }) => ({
    ...lightTheme.typography.Small12medium,
    color: props.$disabled ? lightTheme.colors.grayScale200 : lightTheme.colors.grayScale600,
    maxWidth: '148px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }));

  // Send button style
  const SendButton = styled('button', (props: { $disabled?: boolean, $isActive?: boolean }) => ({
    width: '32px',
    height: '32px',
    border: 'none',
    outline: 'none',
    background: props.$disabled ? lightTheme.colors.grayScale50 : lightTheme.colors.primary,
    borderRadius: '50%',
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: props.$disabled ? 'none' : 'auto',
    cursor: 'pointer',
    ':hover': {
      background: 'rgba(229, 5, 86, 1)'
    },
    ':active': {
      background: 'rgba(204, 4, 77, 1)'
    },
    filter: props.$disabled ? 'none' : 'drop-shadow(0px 0px 12px rgba(255, 5, 96, 0.25))'
  }));


  const handleToggleStyle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpenStyle((prev) => !prev); // Toggle only once
  };

  const handleToggleRatio = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpenRatio((prev) => !prev); // Toggle only once
  };

  return (
    <div
      id="wrapper-textarea"
      className={css({
        position: 'relative',
        height: '124px',
        padding: '16px 4px 16px 16px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: lightTheme.colors.grayScale100,
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: '16px',
        pointerEvents: isLoadingSuggestImages ? 'none' : 'auto',
        ':hover': {
          borderColor: isLoadingSuggestImages ? lightTheme.colors.grayScale100 : lightTheme.colors.grayScale300,
        },
        ':focus-within': {
          borderColor: isLoadingSuggestImages ? lightTheme.colors.grayScale100 : lightTheme.colors.grayScale300,
        }
      })}
    >
      <DynamicTextarea
        isLoadingSuggestImages={isLoadingSuggestImages}
        prompt={prompt}
        setPrompt={setPrompt}
        confirmQueryValue={confirmQueryValue}
      />
      <ActionButtonsContainer>
        {/* Select Style */}
        <Popover
          isOpen={isOpenStyle}
          onClickOutside={() => setIsOpenStyle(false)}
          placement={PLACEMENT.bottom}
          popoverMargin={4}
          overrides={{
            Body: {
              style: ({ $theme }) => ({
                zIndex: '10000',
              }),
            },
          }}
          content={() => (
            <div
              style={{
                width: '292px',
                height: '356px',
                background: lightTheme.colors.white,
                borderRadius: '16px',
                padding: '16px',
              }}
            >
              <h5 style={{ ...lightTheme.typography.Small16Bold, fontWeight: 600, paddingLeft: '8px' }}>
                {t('Choose style')}
              </h5>
              <div style={{ marginTop: '8px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                {STYLES.map(
                  (item, index) =>
                    index !== 0 && (
                      <div
                        className={css({
                          height: '70px',
                          padding: '8px 16px 8px 8px',
                          background: lightTheme.colors.white,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'start',
                          gap: '16px',
                          ':hover': {
                            background: lightTheme.colors.grayScale50,
                          },
                          borderRadius: '8px',
                          cursor: 'pointer',
                        })}
                        onClick={e => {
                          e.preventDefault()
                          e.stopPropagation()
                          setSelectedStyle(prev => (prev.id === item.id ? STYLES[0] : item))
                          setIsOpenStyle(false)
                        }}
                      >
                        <img
                          src={item.icon}
                          alt="icon"
                          style={{ width: '54px', height: '54px', borderRadius: '4px' }}
                          draggable={false}
                        />
                        <p
                          style={{
                            ...lightTheme.typography.Small14regular,
                            color: lightTheme.colors.grayScale600,
                          }}
                        >
                          {t(item.text)}
                        </p>
                        {item.id === selectedStyle.id ? (
                          <div style={{ marginLeft: 'auto' }}>
                            <Icons.CheckIconSmall fill={'#FF0560'} />
                          </div>
                        ) : null}
                      </div>
                    )
                )}
              </div>
            </div>
          )}
          triggerType="click"
        >
          <OptionButton style={{paddingLeft: selectedStyle.id === 0 ? '8px' : '4px', background: isOpenStyle ? lightTheme.colors.grayScale100 : 'auto'}}>
            <div 
              style={{
                display: 'flex',
                alignItems: 'center', // Add this to ensure vertical centering
                gap: '4px',
              }} 
              onClick={handleToggleStyle}
            >
              <IconImage
                src={selectedStyle.icon}
                alt="icon"
                style={{
                  borderRadius: '50%',
                  opacity: isLoadingSuggestImages ? 0.3 : 1.0
                }}
                draggable={false}
              />
              <OptionText $disabled={isLoadingSuggestImages}>
                {selectedStyle.text}
              </OptionText>
            </div>
          </OptionButton>
        </Popover>
        
        {/* Select Ratio */}
        <Popover
          isOpen={isOpenRatio} 
          onClickOutside={() => setIsOpenRatio(false)}
          placement={PLACEMENT.bottom}
          popoverMargin={4}
          overrides={{
            Body: {
              style: ({ $theme }) => ({
                zIndex: '10000',
              }),
            },
          }}
          content={() => (
            <div
              style={{
                width: '292px',
                height: '282px',
                background: lightTheme.colors.white,
                borderRadius: '16px',
                padding: '16px',
              }}
            >
              <h5 style={{ ...lightTheme.typography.Small16Bold, fontWeight: 600, paddingLeft: '8px' }}>
                {t('Choose ratio')}
              </h5>
              <div style={{ marginTop: '8px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                {RATIOS.map((item, index) => (
                  <div
                    className={css({
                      height: '70px',
                      padding: '8px 16px 8px 8px',
                      background: lightTheme.colors.white,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'start',
                      gap: '16px',
                      ':hover': {
                        background: lightTheme.colors.grayScale50,
                      },
                      borderRadius: '8px',
                      cursor: 'pointer',
                    })}
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      setSelectedRatio(item)
                      setIsOpenRatio(false)
                    }}
                  >
                    <img
                      src={item.icon}
                      alt="icon"
                      style={{ width: '54px', height: '54px', borderRadius: '4px' }}
                      draggable={false}
                    />
                    <p
                      style={{
                        ...lightTheme.typography.Small14regular,
                        color: lightTheme.colors.grayScale600,
                      }}
                    >
                      {t(item.text)}
                    </p>
                    {item.id === selectedRatio.id ? (
                      <div style={{ marginLeft: 'auto' }}>
                        <Icons.CheckIconSmall fill={'#FF0560'} />
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          )}
          triggerType="click"
        >
          <OptionButton style={{ background: isOpenRatio ? lightTheme.colors.grayScale100 : 'auto'}}>
            <div 
              style={{
                display: 'flex',
                alignItems: 'center', // Add this to ensure vertical centering
                gap: '4px',
              }} 
              onClick={handleToggleRatio}
            >
              <IconImage 
                src={selectedRatio.smallIcon} 
                alt="icon"
                style={{
                  opacity: isLoadingSuggestImages ? 0.3 : 1.0
                }}
                draggable={false}
              />
              <OptionText $disabled={isLoadingSuggestImages}>
                {selectedRatio.text}
              </OptionText>
            </div>
          </OptionButton>
        </Popover>

        <SendButton
          $disabled={(isDefaultValue || isLoadingSuggestImages) || prompt === ''}
          onClick={() => confirmQueryValue()}
        >
          <Icons.FullArrowRightIcon fill={(isDefaultValue || isLoadingSuggestImages) || prompt === '' ? 'rgba(204, 204, 204, 1)' : '#FFF'} />
        </SendButton>
      </ActionButtonsContainer>
    </div>
  )
}
