import { lightTheme } from '@/customTheme'
import api from '@/services/api'
import { useStyletron } from 'baseui'
import React, { useEffect, useRef, useState } from 'react'
import WrapImage from './WrapImage'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { KIND } from 'baseui/button'
import { MediaImageRepositoryProcessing } from '@/scenes/engine/objects/media-repository/media_image_repository_processing'
import { useAppDispatch } from '@/store/store'
import { setSuggestedImagesFromServer } from '@/store/slices/magicBgTool/action'
import { useSelector } from 'react-redux'
import { selectSuggestedImagesFromServer } from '@/store/slices/magicBgTool/selector'
import { Point } from '@/scenes/engine/objects/media-repository/point'
import { selectUser } from '@/store/slices/user/selectors'
import { Rectangle } from '@/scenes/engine/objects/media-repository/rectangle'
import { useEditorContext } from '@/scenes/engine'
import { t } from 'i18next'
import AsyncImageGrid from '@/components/AsyncImageGrid'

export interface CategoryDetailProps {
  categoryActive: any;
  image: string;
  imageMask: string;
  caption: string | null;
  base64ImageWithoutBg: string;
  ratioFrame: number;
  paddedCrop: Rectangle;
  imageWithoutBgHtmlElement: HTMLImageElement;
  handleClickOnSuggetion: (src: string) => Promise<void>;
}

const CategoryDetail: React.FC<CategoryDetailProps> = ({
  categoryActive,
  image,
  imageMask,
  caption,
  base64ImageWithoutBg,
  ratioFrame,
  paddedCrop,
  imageWithoutBgHtmlElement,
  handleClickOnSuggetion,
}) => {
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const { activeObject } = useEditorContext();
  const user = useSelector(selectUser);
  const suggestedImagesFromServer = useSelector(selectSuggestedImagesFromServer);

  const imageProcessing = new MediaImageRepositoryProcessing();

  // Initialize with cached images or empty array
  const getCachedImages = () => {
    if (!categoryActive || !suggestedImagesFromServer) return [];

    const cachedCategory = suggestedImagesFromServer.find(
      category =>
        category.id === categoryActive.id &&
        // @ts-ignore
        category.bazaartGuid === activeObject.bazaartGuid
    );

    return cachedCategory ? cachedCategory.value : [];
  };

  const [localSuggestedImages, setLocalSuggestedImages] = useState<string[]>(getCachedImages());

  // Reset local state when category or bazaartGuid changes
  useEffect(() => {
    setLocalSuggestedImages(getCachedImages());
    // @ts-ignore
  }, [categoryActive?.id, activeObject.bazaartGuid]);

  const updateReduxStore = (newImages: string[]) => {
    if (!categoryActive) return;

    const existingData = suggestedImagesFromServer?.map(elem => ({ ...elem })) || [];
    const categoryIndex = existingData.findIndex(
      category =>
        category.id === categoryActive.id &&
      // @ts-ignore
        category.bazaartGuid === activeObject.bazaartGuid
    );

    if (categoryIndex >= 0) {
      // Update existing category
      existingData[categoryIndex] = {
        ...existingData[categoryIndex],
        value: newImages
      };
    } else {
      // Add new category
      existingData.push({
        id: categoryActive.id,
        value: newImages,
        // @ts-ignore
        bazaartGuid: activeObject.bazaartGuid
      });
    }

    dispatch(setSuggestedImagesFromServer(existingData));
  };

  const postProcessMagicBgResult = async (inputBase64Image: string): Promise<string> => {
    const base64Image = `data:image/png;base64,${inputBase64Image}`;
    const loadedImage = await imageProcessing.loadImage(base64Image);
    const croppedImage = await imageProcessing.cropHtmlImage(loadedImage, paddedCrop);
    const compositedImage = await imageProcessing.composite(
      croppedImage,
      imageWithoutBgHtmlElement,
      new Point(0, 0)
    );
    return compositedImage.src;
  };

  const fetchSingleImage = async (): Promise<string> => {
    try {
      const result = await api.magicBg(
        image,
        imageMask,
        categoryActive.prompt.replace('{object_name}', caption)
      );
      const processedImage = await postProcessMagicBgResult(result);

      // Update local state and Redux
      setLocalSuggestedImages(prev => {
        const newImages = [...prev, processedImage];
        updateReduxStore(newImages);
        return newImages;
      });

      return processedImage;
    } catch (error) {
      console.error('Error fetching image:', error);
      throw error;
    }
  };

  const handleImageSelect = async (image: string) => {
    setActiveItem(image);
    await handleClickOnSuggetion(image);
  };

  // Only render AsyncImageGrid if we have an active category
  if (!categoryActive) return null;

  return (
    <AsyncImageGrid
      initialImages={localSuggestedImages}
      onFetchImages={fetchSingleImage}
      batchSize={4}
      aspectRatio={ratioFrame}
      loadingPlaceholderSrc={base64ImageWithoutBg}
      selectedImage={activeItem}
      onImageSelect={handleImageSelect}
    />
  );
};

export default CategoryDetail