import { AppContext } from '@/contexts/AppContext'
import { useContext } from 'react'

function useAppContext() {
  const {
    isMobile,
    setIsMobile,
    activePanel,
    setActivePanel,
    templates,
    setTemplates,
    shapes,
    setShapes,
    activeSubMenu,
    setActiveSubMenu,
    uploads,
    setUploads,
    currentTemplate,
    setCurrentTemplate,
    removeBgSuccess,
    setRemoveBgSuccess,
    isOpenInspector,
    setIsOpenInspector,
    removeBgAbortController,
    setRemoveBgAbortController,
    hasBgImage,
    setHasBgImage,
    disableEditorState,
    setDisableEditorState,
    disableEditor,
    setDisableEditor,
    isLoadedJsonSuccess,
    setIsLoadedJsonSuccess,
    objDragging,
    setObjDragging,
    isReplacingImage,
    setIsReplacingImage,
    isOpenPixelManipulationObject,
    setisOpenPixelManipulationObject,
    isOpenPixelManipulationWithAnimation,
    setIsOpenPixelManipulationWithAnimation,
    popoverActive,
    setPopoverActive,
    toolType,
    setToolType,
    showModalType,
    setShowModalType,
    isOpenTutorial,
    setIsOpenTutorial,
    canCancelRemoveBg,
    setCanCancelRemoveBg,
    cancelPixelManipulation,
    saveProjectState,
    setSaveProjectState,
    isOpenFontFamily, 
    setIsOpenFontFamily
  } = useContext(AppContext)
  return {
    isMobile,
    setIsMobile,
    activePanel,
    setActivePanel,
    templates,
    setTemplates,
    shapes,
    setShapes,
    activeSubMenu,
    setActiveSubMenu,
    uploads,
    setUploads,
    currentTemplate,
    setCurrentTemplate,
    removeBgSuccess,
    setRemoveBgSuccess,
    isOpenInspector,
    setIsOpenInspector,
    removeBgAbortController,
    setRemoveBgAbortController,
    hasBgImage,
    setHasBgImage,
    disableEditorState,
    setDisableEditorState,
    disableEditor,
    setDisableEditor,
    isLoadedJsonSuccess,
    setIsLoadedJsonSuccess,
    objDragging,
    setObjDragging,
    isReplacingImage,
    setIsReplacingImage,
    isOpenPixelManipulationObject,
    setisOpenPixelManipulationObject,
    isOpenPixelManipulationWithAnimation,
    setIsOpenPixelManipulationWithAnimation,
    popoverActive,
    setPopoverActive,
    toolType,
    setToolType,
    showModalType,
    setShowModalType,
    isOpenTutorial,
    setIsOpenTutorial,
    canCancelRemoveBg,
    setCanCancelRemoveBg,
    cancelPixelManipulation,
    saveProjectState,
    setSaveProjectState,
    isOpenFontFamily, 
    setIsOpenFontFamily
  }
}

export default useAppContext
