import { IElement, IFontFamily, IStorePack, IUpload, TemplateDetails } from '@/interfaces/editor'
import axios, { AxiosInstance } from 'axios'
import { Cookies } from 'react-cookie'
import store from '@/store/store'
import { setOpenModalTryPremium, setUser, setUserIsPremium } from '@/store/slices/user/actions'
import { SignInManager } from '@/scenes/Editor/components/Navbar/components/SignInManager'
import { customAmplitude } from '@/utils/customAmplitude'
import { MediaImageType } from '@/scenes/engine/objects/media-repository/media_image_type'
import { MediaImageRepository } from '@/scenes/engine/objects/media-repository/media_image_repository'
import { Size } from '@/scenes/engine/objects/media-repository/size'
import { MediaImageRepositoryProcessing, ResizeMode, ResizeOptions } from '@/scenes/engine/objects/media-repository/media_image_repository_processing'
import ImageJs from 'image-js'

type Template = any
class ApiService {
  base: AxiosInstance
  global: AxiosInstance
  auth: AxiosInstance

  constructor() {
    this.base = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    })

    this.global = axios.create()
    this.auth = axios.create({
      baseURL: process.env.REACT_APP_AUTH_API_URL,
    })
  }  

  refreshFirebaseToken(userId: number): Promise<string> {
    return new Promise((resolve, reject) => {
      // omg - it's essential that we append '/' to the end of the login path
      this.auth.get(`/user/${userId}/`, {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
            "Referrer-Policy": "origin",
            'Authorization': `ApiKey ${SignInManager.getInstance().accessToken}`,
          }
        })
        .then(({ data }) => {
          resolve(data?.firebase_token)
        })
        .catch(err => reject(err))
    })
  }


  login(sessionToken: string, type: string, userId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let params = {
        session_token_type: type,
        session_token: sessionToken,
        user_id: userId
      };
      // omg - it's essential that we append '/' to the end of the login path
      this.auth
        .post('/login/', params, {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8",
            "Referrer-Policy": "origin"
          }
        })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(err => reject(err))
    })
  }

  listTransactions(): Promise<any> {
    return new Promise((resolve, reject) => {
      // omg - it's essential that we append '/' to the end of the purchase path
      this.auth
        .get('/purchase/', {
          headers: {
            Accept: "application/json",
            Authorization: `ApiKey ${SignInManager.getInstance().accessToken}`,
          }
        })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(err => {
          let cookies = new Cookies()
          cookies.remove('userInfo', { path: '/' })
          store.dispatch(setUser(null))
          store.dispatch(setUserIsPremium(false))
          store.dispatch(
            setOpenModalTryPremium({
              isOpen: false,
              source: null,
            })
          )
          reject(err)
        })
    })
  }

  // UPLOADS
  getSignedURLForUpload(props: { name: string }): Promise<{ url: string }> {
    return new Promise((resolve, reject) => {
      this.base
        .post('/uploads', props)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(err => reject(err))
    })
  }

  updateUploadFile(props: { name: any }): Promise<any> {
    return new Promise((resolve, reject) => {
      // this.base
      //   .put('/uploads', props)
      //   .then(({ data }) => {
      //     resolve(data)
      //   })
      //   .catch(err => reject(err))
      resolve({ url: props.name })
    })
  }

  getUploads(): Promise<IUpload[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get('/photo_item/?store=326')
        var data_m = data.objects.map(x => {
          return {
            id: x.id.toString(),
            url: x.image,
            name: x.image,
            folder: '/',
            contentType: '',
            type: '',
          }
        })
        var d = data_m as unknown as IUpload[]
        resolve(d)
      } catch (err) {
        reject(err)
      }
    })
  }

  deleteUpload(id: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.base.delete(`/uploads/${id}`)
        resolve(response)
      } catch (err) {
        reject(err)
      }
    })
  }


  getTemplatesSuperCategories(): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get(`/templateuploadcategorycollection/?is_website_only=0`)
        const superCategories = data.objects.filter(x=>x.name != 'Video')
        resolve(superCategories)
      } catch (err) {
        reject(err)
      }
    })
  }

  getTemplateDetails(templateId: string): Promise<TemplateDetails> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get(`/template_upload/${templateId}/?version__lte=1000`)
        resolve(data)
      } catch (err) {
        reject(err)
      }
    })
  }

  getCategoreisByTemplate(templateId: string): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get(`/template_upload/${templateId}/?version__lte=1000`)
        resolve(
          data.categories
            .map(x => {
              var parts = x.split("/")
              var lastSegment = parts.pop() || parts.pop()
              return Number(lastSegment)
            }))
      } catch (err) {
        reject(err)
      }
    })
  }

  getTemplatesByCategory(id: number): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get(
          `/template_upload/?categories=${id}&limit=0&video=&version__lte=1000`
        )
        resolve(data.objects)
      } catch (err) {
        reject(err)
      }
    })
  }

  getCategoriesBySuperCategory(id: number): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get(
          `/admin_template_category/?collections=${id}&locales=he&isHidden=false&contains_video=false&limit=100&version__lte=13`
        )
        resolve(data.objects.filter(x => x.is_web_visible))
      } catch (err) {
        reject(err)
      }
    })
  }

  searchTemplate(querty, signal?: AbortSignal): Promise<IStorePack[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get(
          `/template_upload/?offset=0&limit=0&video=&version__lte=1000&search=${querty}`,
          {signal}
        )
        resolve(data.objects)
      } catch (err) {
        reject(err)
      }
    })
  }
  getCategoryDetails(id: number): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get(
          `/admin_template_category/${id}/`
        )
        resolve(data.is_web_visible ? data : null)
      } catch (err) {
        reject(err)
      }
    })
  }
  //CREATIONS

  createCreation(props: Partial<Template>): Promise<Template> {
    return new Promise((resolve, reject) => {
      this.base
        .post('/creations', props)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(err => reject(err))
    })
  }

  getCreations(): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get('/creations')
        resolve(data)
      } catch (err) {
        reject(err)
      }
    })
  }

  async listAllTemplateAsset(templateId: string, signal?: AbortSignal): Promise<any>{
    let assets = []
    let off = 0
    let assets_step
    do {
          assets_step = await this.base.get(`/templateasset/?limit=100&offset=${off * 100}&template=` + templateId, { signal })
          assets.push(...assets_step.data.objects)
          off++
    } while (assets_step.data.meta.total_count > assets.length)
    return assets
  }

  loadTemplateAsset(templateId: string, type: MediaImageType, layerName?:string, signal?: AbortSignal): Promise<any>{
    try {
      let assetPromise;
      if (layerName) {
        assetPromise = this.base.get(`/templateasset/?type=${type}&layer=${layerName}&template=${templateId}&order_by=-timestamp&limit=1`, { signal })
      } else {
        assetPromise = this.base.get(`/templateasset/?type=${type}&template=${templateId}&order_by=-timestamp`, { signal })
      }
      return assetPromise;
    } catch (err) {
      if (err.code === "ERR_CANCELED") {
        return null;
      } else {
        return err;
      }
    }
  }

  getCreationById(id: string, signal?: AbortSignal): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        let configJsonPromise = this.loadTemplateAsset(id, MediaImageType.original, "config.json", signal);
        let layersJsonPromise = this.loadTemplateAsset(id, MediaImageType.original, "layer.json", signal);
        let latestAssetPromise = this.loadTemplateAsset(id, MediaImageType.latest, null, signal);

        return Promise.all([configJsonPromise, layersJsonPromise, latestAssetPromise]).then((assets)=>{
          const jsonConfigUrl = assets[0]?.data?.objects[0]?.asset;
          const jsonLayersUrl = assets[1]?.data?.objects[0]?.asset;
          const latestAssets = assets[2]?.data?.objects;

          const downloadJsonConfigPromise = this.downloadAsset(jsonConfigUrl, {signal})
          const downloadJsonLayersPromise = this.downloadAsset(jsonLayersUrl, {signal})

          return Promise.all([downloadJsonConfigPromise, downloadJsonLayersPromise]).then((configAssets)=>{
            let jsonConfig = configAssets[0];
            let jsonLayers = configAssets[1];
            let BtDraftObject = jsonConfig?.data?.BtDraftObject
            //TODO: remove this line.it temp workaround becauase AI tempaltes not return templateId - Dror should fix it on upload template flow
            BtDraftObject.templateId = id;
            resolve({ objects: jsonLayers?.data, latestAssets: latestAssets, config: BtDraftObject })
          });
        })
      } catch (err) {
        if (err.code === "ERR_CANCELED") {
          resolve(false)
        } else {
          reject(err)
        }
      }
    })
  }
  updateCreation(id: string, props: Partial<Template>): Promise<Template> {
    return new Promise((resolve, reject) => {
      this.base
        .put(`/creations/${id}`, props)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(err => reject(err))
    })
  }

  // ELEMENTS
  getElements(): Promise<IElement[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get('/elements')
        resolve(data)
      } catch (err) {
        reject(err)
      }
    })
  }
  updateTemplateLayout(id: number, props: Partial<Template>): Promise<Template> {
    // let params =
    return new Promise((resolve, reject) => {
      this.base
        .post(`/templateasset/`, props)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(err => reject(err))
    })
  }

  // FONTS
  getFontPacks(): Promise<IFontFamily[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get('/store/?category__in=3&order_by=priority')
        resolve(data.objects)
      } catch (err) {
        reject(err)
      }
    })
  }

  getFonts(): Promise<IFontFamily[]> {
    return new Promise(async (resolve, reject) => {
      try {
        // const { data } = await this.base.get('/photo_item/?store__category__in=3&limit=1000&offset=0')
        const { data } = await this.base.get(
          '/photo_item/?store__category__in=3&limit=1000&offset=0'
        )
        // console.log('data', data)

        var data_m = data.objects.map(x => {
          return {
            id: x.id.toString(),
            family: x.font_system_name,
            files: { regular: x.file },
            variants: ['regular'],
            subsets: ['latin'],
            version: 'v1',
            lastModified: '2022-03-01',
            kind: 'webfonts#webfont',
            category: x.source_platform,
            desc: x.description,
            name: x.font_nickname,
          }
        })
        var d = data_m as unknown as IFontFamily[]
        resolve(d)
      } catch (err) {
        reject(err)
      }
    })
  }

  getBackgroundPacks(): Promise<IStorePack[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get('/store/?category__in=11,12&order_by=priority')
        resolve(data.objects)
      } catch (err) {
        reject(err)
      }
    })
  }

  searchStickers(querty, signal?: AbortSignal): Promise<IStorePack[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get(`/photo_item/?search=${querty}`,{ signal })
        const dataWithoutAnimated = data.objects.filter(x => !x.image.endsWith('gif'))
        resolve(dataWithoutAnimated)
      } catch (err) {
        reject(err)
      }
    })
  }

  getGraphicPacks(): Promise<IStorePack[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get('/store/?category__in=14,1&order_by=priority')
        resolve(data.objects)
      } catch (err) {
        reject(err)
      }
    })
  }

  getFilterPacks(): Promise<IStorePack[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get('/store/?category__in=15,1&order_by=priority')
        resolve(data.objects)
      } catch (err) {
        reject(err)
      }
    })
  }

  getPack(packId: string): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get('/photo_item/?store=' + packId)
        resolve(data.objects)
      } catch (err) {
        reject(err)
      }
    })
  }


  bgRemove(image: any, width: string, height: string, abortSignal?: AbortSignal): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        customAmplitude('Magic Cut Out')
        
        let formData = new FormData()
        formData.append('image', image)
        formData.append('width', width)
        formData.append('height', height)

        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
          signal: abortSignal,
        }

        const { data } = await this.base.post('/bgremove', formData, config)

        resolve(data)
      } catch (err) {
        // console.log(err)
        let cookies = new Cookies()
        cookies.get('sapi_bgremove')
        reject(err)
      }
    })
  }

  getFilters(): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get('store_category/')
        resolve(data.objects)
      } catch (err) {
        reject(err)
      }
    })
  }

  getImageElement(): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get('/photo_item/?store__category=15&limit=1000&offset=0')
        resolve(data.objects)
      } catch (err) {
        reject(err)
      }
    })
  }

  inpaintImage(formData): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        customAmplitude('Magic Heal')
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `ApiKey ${SignInManager.getInstance().accessToken}`,
          },
          maxRedirects: 10
        }

        const data = await this.auth.post('/bheal/', formData, config)
        resolve(data)
      } catch (err) {
        reject(err)
      }
    })
  }

  prepareUrl(inputUrl: any): string {
    if (!inputUrl) {
      return;
    }
    let url = (typeof inputUrl === "string") ? inputUrl : inputUrl.toString();
    if (this.base.defaults.baseURL.startsWith('/')) {
      url = url.replace('https://media.bazaart.me', '/media');
      url = url.replace('https://d2ch1c8885oq0w.cloudfront.net', '/cloudfront');
      url = url.replace('https://local.design.bazaart.me:3000/cloudfront', '/cloudfront');
    }
    return url;
  }

  downloadAsset(inputUrl: URL, inputParams: any = null): Promise<any> {
    let url = this.prepareUrl(inputUrl);
    return new Promise(async (resolve, reject) => {
      try {
        let params = {
          url: url,
          method: 'GET',
          crossOrigin: 'Anonymous'
        }
        params = Object.assign(params, inputParams || {});
        const result = await this.global(params)
        resolve(result)
      } catch (err) {
        reject(err)
      }
    })
  }
  async enhance(base64image: any): Promise<any> {
    let maxSupportedEdge = 1024
    let minSupportedEdge = 512


    const base64Prefix = 'data:image/png;base64,'
    let image = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.loadImage(base64Prefix + base64image)
    let tooBig = image.width > maxSupportedEdge || image.height > maxSupportedEdge;
    let tooSmall = image.width < minSupportedEdge || image.height < minSupportedEdge;
    
    if (tooBig || tooSmall){
      let resizeOptions = MediaImageRepositoryProcessing.defaultResizeOptions
      resizeOptions.allowUpsampling = true
      base64image = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.resizeBase64ToMaxEdgeSize(image.src, maxSupportedEdge, resizeOptions);
      base64image = base64image.replace(base64Prefix, '');
    }

    let path = 'n4c14lg80ru6fy'
    let params = {
      input: { scale:2, img: base64image}
    };
    return this.runpod(path, params)
  }

  replicate(params: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            // 'content-type': 'multipart/form-data',
            "Content-Type": "application/json; charset=utf-8",
            "Accept": "application/json",
            Authorization: `ApiKey ${SignInManager.getInstance().accessToken}`,
          },
          maxRedirects: 10,
        }
        const data = await this.auth.post('/replicate', params, config)
        
        let completed;
        for (let i = 0; i < 10; i++) {
          // @ts-ignore
          const latest = await this.auth.get(`/replicate/${data.data.id}`, config)
          // @ts-ignore
          if (latest.data.status !== "starting" && latest.data.status !== "processing") {
            completed = latest;
            break;
          }
          // Wait for 2 seconds and then try again.
          await new Promise((resolve) => setTimeout(resolve, 2000));
        }

        if(completed == undefined)
        {
          throw 'Time out';
        }
        resolve(completed.data.output)
      } catch (err) {
        reject(err)
      }
    })
  }

  getContentTranslation(): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get(`/localization/`)
        if(data?.objects?.length>0 && data?.objects[0].localizations)
        resolve(data.objects[0].localizations)
      } catch (err) {
        reject(err)
      }
    })
  }

  inpaintImageUnauth(formData): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        customAmplitude('Magic Heal')
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
          maxRedirects: 10
        }

        const data = await this.base.post('/magic_heal/', formData, config)
        resolve(data)
      } catch (err) {
        reject(err)
      }
    })
  }

  async captionImageUnauth(base64image: any): Promise<string> {
    let path = 'caption_image'
    let params = {
      input: { 
        "questions": ["describe the image in one noun", "describe what’s in the image"],
        "data_url": base64image,
        "task": "visual_question_answering"
      }
    };

    let result = await this.runpodUnauth(path, params)
    
    return result["results"][0]["answer"] 
  }

  magicBgUnauth(base64image: any, base64Mask: any, prompt: string): Promise<any> {
    let path = 'magic_background'
    let params = {
      input: { 
        negative_prompt:"text, (((watermark))), low quality, ugly, deformed, boring, bad quality, cartoon, ((disfigured)), ((bad art)), ((deformed)), ((poorly drawn)), ((extra limbs)), ((close up)), ((b&w)), weird colors, blurry, ugly, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, out of frame, extra limbs, disfigured, deformed, body out of frame, blurry, bad anatomy, blurred, watermark, grainy, signature, cut off, draft, low detail, low quality, double face, 2 faces, cropped, ugly, low-res, tiling, grainy, cropped, ostentatious, ugly, oversaturated, grain, low resolution, disfigured, blurry, bad anatomy, disfigured, poorly drawn face, mutant, mutated, extra limb, ugly, poorly drawn hands, missing limbs, blurred, floating limbs, disjointed limbs, deformed hands, blurred, out of focus, long neck, long body, ugly, disgusting, childish, cut off cropped, distorted, imperfect, surreal, bad hands, text, error, extra digit, fewer digits, cropped , worst quality, low quality, normal quality, jpeg artifacts, signature, watermark, username, blurry, artist name, Lots of hands, extra limbs, extra fingers, conjoined fingers, deformed fingers, old, ugly eyes, imperfect eyes, skewed eyes , unnatural face, stiff face, stiff body, unbalanced body, unnatural body, lacking body, details are not clear, cluttered, details are sticky, details are low, distorted details, ugly hands, imperfect hands, (mutated hands and fingers:1.5), (long body :1.3), (mutation, poorly drawn :1.2) bad hands, fused ha nd, missing hand, disappearing arms, hands, disappearing thigh, disappearing calf, disappearing legs, ui, missing fingers",
        prompt: prompt,
        img: base64image,
        mask_img: base64Mask
      }
    };
    return this.runpodUnauth(path, params)
  }

  runpodUnauth(path: string, params: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            // 'content-type': 'multipart/form-data',
            "Content-Type": "application/json; charset=utf-8",
            "Accept": "application/json",
          },
          maxRedirects: 10,
        }
        const data = await this.base.post(`/${path}/run`, params, config)
        
        let completed;
        for (let i = 0; i < 20; i++) {
          // @ts-ignore
          const latest = await this.base.get(`/${path}/status/${data.data.id}`, config)
          // @ts-ignore
          if (latest.data.status.toLowerCase() === "completed" || latest.data.status.toLowerCase() === "failed") {
            completed = latest;
            break;
          }
          // Wait for 2 seconds and then try again.
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }

        if(completed == undefined) {
          customAmplitude('Magic Backgrounds Error')
          throw 'Time out';
        }
        customAmplitude('Magic Backgrounds Start')
        
        resolve(completed.data.output)
      } catch (err) {
        reject(err)
      }
    })
  }

  async captionImage(base64image: any): Promise<string> {
    let path = 'u1fyiccnr8so8v'
    let params = {
      input: { 
        "questions": ["describe the image in one noun", "describe what’s in the image"],
        "data_url": base64image,
        "task": "visual_question_answering"
      }
    };

    let result = await this.runpod(path, params)
    
    return result["results"][0]["answer"] 
  }

  magicBg(base64image: any, base64Mask: any, prompt: string): Promise<any> {
    let path = '5lqk0462ddu6s1'
    let params = {
      input: { 
        negative_prompt:"text, (((watermark))), low quality, ugly, deformed, boring, bad quality, cartoon, ((disfigured)), ((bad art)), ((deformed)), ((poorly drawn)), ((extra limbs)), ((close up)), ((b&w)), weird colors, blurry, ugly, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, out of frame, extra limbs, disfigured, deformed, body out of frame, blurry, bad anatomy, blurred, watermark, grainy, signature, cut off, draft, low detail, low quality, double face, 2 faces, cropped, ugly, low-res, tiling, grainy, cropped, ostentatious, ugly, oversaturated, grain, low resolution, disfigured, blurry, bad anatomy, disfigured, poorly drawn face, mutant, mutated, extra limb, ugly, poorly drawn hands, missing limbs, blurred, floating limbs, disjointed limbs, deformed hands, blurred, out of focus, long neck, long body, ugly, disgusting, childish, cut off cropped, distorted, imperfect, surreal, bad hands, text, error, extra digit, fewer digits, cropped , worst quality, low quality, normal quality, jpeg artifacts, signature, watermark, username, blurry, artist name, Lots of hands, extra limbs, extra fingers, conjoined fingers, deformed fingers, old, ugly eyes, imperfect eyes, skewed eyes , unnatural face, stiff face, stiff body, unbalanced body, unnatural body, lacking body, details are not clear, cluttered, details are sticky, details are low, distorted details, ugly hands, imperfect hands, (mutated hands and fingers:1.5), (long body :1.3), (mutation, poorly drawn :1.2) bad hands, fused ha nd, missing hand, disappearing arms, hands, disappearing thigh, disappearing calf, disappearing legs, ui, missing fingers",
        prompt: prompt,
        img: base64image,
        mask_img: base64Mask
      }
    };
    return this.runpod(path, params)
  }

  runpod(path: string, params: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const config = {
          headers: {
            // 'content-type': 'multipart/form-data',
            "Content-Type": "application/json; charset=utf-8",
            "Accept": "application/json",
            Authorization: `ApiKey ${SignInManager.getInstance().accessToken}`,
          },
          maxRedirects: 10,
        }
        const data = await this.auth.post(`/runpod/${path}/run`, params, config)
        
        let completed;
        for (let i = 0; i < 20; i++) {
          // @ts-ignore
          const latest = await this.auth.get(`/runpod/${path}/status/${data.data.id}`, config)
          // @ts-ignore
          if (latest.data.status.toLowerCase() === "completed" || latest.data.status.toLowerCase() === "failed") {
            completed = latest;
            break;
          }
          // Wait for 2 seconds and then try again.
          await new Promise((resolve) => setTimeout(resolve, 1500));
        }

        if(completed == undefined) {
          customAmplitude('Magic Backgrounds Error')
          throw 'Time out';
        }
        customAmplitude('Magic Backgrounds Start')
        
        resolve(completed.data.output)
      } catch (err) {
        reject(err)
      }
    })
  }

  // magic background
  getMagicBgPromptClassifications(): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get(`/aipromptclassification/?category=4&isHidden=true`)
        resolve(data.objects)
      } catch (err) {
        reject(err)
      }
    })
  }

  getMagicBgPromptsByClassification(classificationId: string): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get(`/magicportraitprompt/?classification=${classificationId}&isHidden=true`)
        resolve(data.objects)
      } catch (err) {
        reject(err)
      }
    })
  }

  getMagicBgPromptsByClassifications(classificationIds: string[]): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get(`/magicportraitprompt/?classification__in=${classificationIds.toString()}&isHidden=true`)
        resolve(data.objects)
      } catch (err) {
        reject(err)
      }
    })
  }

  getMagicBgPromptsBySubject(subject: string): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get(`/magicportraitprompt/?subjects__name=${subject}`)
        resolve(data.objects)
      } catch (err) {
        reject(err)
      }
    })
  }

  getWhatsNew(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get(`/whatsnew/?is_active=true&platforms__name__in=web`)
        resolve(data)
      } catch (err) {
        reject(err)
      }
    })
  }

  async aiImage(prompt: string, targetWidth: number, targetHeight: number): Promise<any> {
    const path = 'huq45p91k8czpw'
    const base64Prefix = 'data:image/png;base64,'
    
    // let image = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.createColorImage('#ffffff', targetWidth, targetHeight);
    // let imageInBase64 = await MediaImageRepository.getInstance()._mediaImageRepositoryProcessing.blobUrlToBase64(image);
    // let mask = await  ImageJs.load(image);
    // let maskInBase64 = mask.grey().toDataURL();

    let params = {
      input: { 
        // img: imageInBase64.replace(base64Prefix, ''),
        // mask_img: maskInBase64.replace(base64Prefix, ''),
        negative_prompt:"text, (((watermark))), low quality, ugly, deformed, boring, bad quality, cartoon, ((disfigured)), ((bad art)), ((deformed)), ((poorly drawn)), ((extra limbs)), ((close up)), ((b&w)), weird colors, blurry, ugly, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, out of frame, extra limbs, disfigured, deformed, body out of frame, blurry, bad anatomy, blurred, watermark, grainy, signature, cut off, draft, low detail, low quality, double face, 2 faces, cropped, ugly, low-res, tiling, grainy, cropped, ostentatious, ugly, oversaturated, grain, low resolution, disfigured, blurry, bad anatomy, disfigured, poorly drawn face, mutant, mutated, extra limb, ugly, poorly drawn hands, missing limbs, blurred, floating limbs, disjointed limbs, deformed hands, blurred, out of focus, long neck, long body, ugly, disgusting, childish, cut off cropped, distorted, imperfect, surreal, bad hands, text, error, extra digit, fewer digits, cropped , worst quality, low quality, normal quality, jpeg artifacts, signature, watermark, username, blurry, artist name, Lots of hands, extra limbs, extra fingers, conjoined fingers, deformed fingers, old, ugly eyes, imperfect eyes, skewed eyes , unnatural face, stiff face, stiff body, unbalanced body, unnatural body, lacking body, details are not clear, cluttered, details are sticky, details are low, distorted details, ugly hands, imperfect hands, (mutated hands and fingers:1.5), (long body :1.3), (mutation, poorly drawn :1.2) bad hands, fused hand, missing hand, disappearing arms, hands, disappearing thigh, disappearing calf, disappearing legs, ui, missing fingers",
        prompt: prompt,
        width: targetWidth,
        height: targetHeight,
        num_inference_steps: 3,
        strength: 1.0
      }
    };
    let output = await this.runpod(path, params)
    return base64Prefix + output[0]
  }
}

export default new ApiService()
