import useAppContext from '@/hooks/useAppContext'
import ModalCustom from '@/scenes/Editor/components/Modal/Modal'
import { ShowModalType } from '@/scenes/engine/common/constants'
import { useTranslation } from 'react-i18next'

function ModalNSFWError() {
  const { showModalType, setShowModalType } = useAppContext()
  const { t } = useTranslation()

  const handleCloseModal = () => {
    setShowModalType(null)
  }

  return (
    <ModalCustom
      isOpen={showModalType === ShowModalType.NSFW_ERROR}
      onClose={handleCloseModal}
      content={{
        header: t('Content not allowed'),
        desc: t('Sorry, we can’t create that. Please try something else.'),
      }}
      action={{
        primary: {
          name: t('OK'),
          handle: handleCloseModal
        },
      }}
    ></ModalCustom>
  )
}

export default ModalNSFWError