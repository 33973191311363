import { lightTheme } from '@/customTheme'
import { useEffect, useState } from 'react'
import Icons from '../../../../Icons'
import {Icon1, Icon2, Icon3, Icon4, Icon5, Icon6, Icon7} from '../../../../Icons/IconsModalPremium'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import { KIND, SHAPE } from 'baseui/button'
import { selectOpenModalTryPremium, selectUser, selectUserIsPremium } from '@/store/slices/user/selectors'
import { useSelector } from 'react-redux'
import { styled, useStyletron } from 'baseui'
import { SignInManager } from '../../SignInManager'
import BgTryPremiumSuccess from '@assets/images/premium_success.jpeg'
import { useAppDispatch } from '@/store/store'
import Loading from '@/components/Loading'
import { setOpenModalTryPremium } from '@/store/slices/user/actions'
import { useTranslation } from 'react-i18next'
import VideoIntroRemove from '@assets/video/intro-remove.mp4'
import { customAmplitude } from '@/utils/customAmplitude'
import { ShowModalType } from '@/scenes/engine/common/constants'
import useAppContext from '@/hooks/useAppContext'
import premiumPoster from "@assets/images/try_premium_frame_0.jpg"
import ChooseAPlanV3 from './ChooseAPlanV3'
import ContentForPlanV3 from './ContentForPlanV3'
import { t } from 'i18next'

const CONTENT_MODAL_PREMIUM = [
  {
    content: 'Remove people and objects from your photos',
    icon: <Icon1 />,
  },
  {
    content: 'Create stunning AI backgrounds',
    icon: <Icon7 />,
  },
  {
    content: 'Find a design template for your every need',
    icon: <Icon2 />,
  },
  {
    content: 'Use a huge collection of graphics and fonts',
    icon: <Icon3 />,
  },
  {
    content: 'Resize your designs in a click',
    icon: <Icon4 />,
  },
  {
    content: 'Download without watermark',
    icon: <Icon5 />,
  },
  {
    content: 'Design on the go with Bazaart’s mobile apps',
    icon: <Icon6 />,
  },
]
const WrapAutoScroll = styled('div', (props: any) => ({
  boxSizing: 'border-box',
  height: '100%',
  overflowY: 'scroll',
  overflowX: 'hidden',
  '::-webkit-scrollbar': {
    width: '8px',
    margin: '20px 0',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: lightTheme.colors.grayScale100,
    minHeight: '100px ',
  },
  '::-webkit-scrollbar-thumb:hover': {
    cursor: 'pointer',
    backgroundColor: lightTheme.colors.grayScale150,
  },
  ...props.style,
}))

function ModalTryPremiumV3({handleOpenTutorial, planActive, setPlanActive}) {
  const user = useSelector(selectUser)
  const [isOpenPayment, setIsOpenPayment] = useState(true)
  const [isOpenChooseAPlan, setIsOpenChooseAPlan] = useState(true)
  const [planDetail, setPlanDetail] = useState(null)
  
  const hasPremium = useSelector(selectUserIsPremium)
  const dispatch = useAppDispatch()
  const openModalTryPremium = useSelector(selectOpenModalTryPremium)
  const { setShowModalType, isOpenPixelManipulationObject } = useAppContext()

  useEffect(() => {
    return () => {
      setIsOpenPayment(false)
    }
  }, [hasPremium])

  const { t } = useTranslation()
  
  useEffect(() => {
      setTimeout(async () => {
        SignInManager.getInstance().setupPaddle()
        
        let monthlyPlan = await SignInManager.getInstance().getMonthlyPlan()
        
        let yearlyPlan = await SignInManager.getInstance().getYearlyPlan()
  
        setPlanDetail({
          month: {
            price: monthlyPlan?.recurring?.price?.gross,
            id: monthlyPlan.plan_id,
            canFreeTrial: monthlyPlan.recurring.subscription.trial_days > 0,
          },
          year: {
            price: yearlyPlan?.recurring?.price?.gross,
            id: yearlyPlan.plan_id,
            canFreeTrial: yearlyPlan.recurring.subscription.trial_days > 0,
          },
        })
      })
  }, [user?.userHadAFreeTrial])

  const setupPreOpenPaddle = () => {
    const analytics = {
      Source: 'Metsikon',
      Type: 'Standard',
      Duration: planActive === 1 ? 'Monthly' : 'Yearly',
      Email: user.email,
    }
    SignInManager.getInstance().pay(
      user.platformType,
      user.email,
      user.userId,
      success => {
        setIsOpenPayment(false)
      },
      planActive === 1 ? planDetail?.month?.id : planDetail?.year?.id,
      analytics
    )
    customAmplitude('Premium Selected Plan', {
      Plan: planActive === 1 ? 'Monthly' : 'Yearly',
      Type: 'Metsikon'
    })
  }

  useEffect(() => {
    if (user && openModalTryPremium.source) {
      setIsOpenPayment(true)
      setIsOpenChooseAPlan(false)
      setTimeout(() => {
        setupPreOpenPaddle()
      })
    }
  }, [user, openModalTryPremium?.source, isOpenPayment, planDetail])

  useEffect(() => {
    customAmplitude('Webapp_split_test_Onboarding_metsikon', {
      user: 'Metsikon_Plans'
    })
  }, [])

  return (
    <>
      <div
        style={{
          width: '100vw',
          height: '100vh',
          maxWidth: '920px ',
          maxHeight: '652px',
          minWidth: '920px ',
          minHeight: '652px',
          flexDirection: 'row',
          background: 'rgba(255, 255, 255, 1)',
          borderRadius: '16px',
          overflow: 'hidden',
          position: 'absolute',
          display: 'flex'
        }}
        id="modal-try-premium"
      >
        {!user?.userIsPremium ? (
          <>
            {/* left */}
            {!isOpenPayment ? (
              <WrapAutoScroll
                style={{
                  padding: '48px 40px 16px 48px',
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'left',
                  width: '50%',
                }}
              >
                <h2 style={{ margin: 0, ...lightTheme.typography.Header26bold }}>
                  {t('Try Bazaart Premium')}
                </h2>
                <h4
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '8px',
                    margin: '8px 0 4px 0',
                    ...lightTheme.typography.Small16Bold,
                    fontFamily: 'ES-Rebond-Grotesque-Classic, Rubik, Roboto',
                  }}
                >
                  {t('Give yourself extra design superpowers!')}
                </h4>
                {
                  CONTENT_MODAL_PREMIUM.map((item, index) => (
                    <h4
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '8px',
                        margin: '20px 0 0 0',
                        ...lightTheme.typography.Small14regular,
                      }}
                    >
                      {item.icon}
                      <span style={{ ...lightTheme.typography.Small14regular, marginLeft: index === 0 ? '-3px' : 0 }}>{t(item.content)}</span>
                    </h4>
                  ))
                }
                <div style={{ marginTop: 'auto', textAlign: 'center', marginBottom: user ? '32px' : '0' }}>
                  <ButtonCustom
                    type={SizeButton.LARGE}
                    kind={KIND.primary}
                    style={{
                      position: 'relative',
                      ...lightTheme.typography.Small14Semibold,
                      color: '#fff',
                      marginBottom: 0,
                      marginTop: '8px',
                    }}
                    onClick={e => {
                      setupPreOpenPaddle()
                    }}
                  >
                    {user?.userHadAFreeTrial ? t('Continue') : t('Try it now!')}
                  </ButtonCustom>
                  {!user ? (
                    <h4
                      style={{
                        marginBottom: 0,
                        marginTop: '8px',
                        ...lightTheme.typography.Small12medium,
                        padding: '0 16px',
                        color: 'rgba(153, 153, 153, 1)',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                          setShowModalType(ShowModalType.SIGN_IN)
                          dispatch(
                            setOpenModalTryPremium({
                              ...openModalTryPremium,
                              isOpen: false,
                            })
                          )
                        }
                      }
                    >
                      <span style={{ fontWeight: '400' }}>{t('Already Premium')}?</span> {t('Sign in')}
                    </h4>
                  ) : null}
                </div>
              </WrapAutoScroll>
            ) : !isOpenChooseAPlan ? (
              <PaymentInfo setIsOpenChooseAPlan={setIsOpenChooseAPlan} />
            ) : (
                <ChooseAPlanV3
                    onConfirmPlan={value => {
                    // setPlanActive(value)
                    if(!user) {
                      setIsOpenChooseAPlan(false)
                      setShowModalType(ShowModalType.SIGN_IN)
                      dispatch(
                          setOpenModalTryPremium({
                          ...openModalTryPremium,
                          isOpen: false,
                          callback: () => {
                              handleOpenTutorial()
                          },
                          })
                      )
                      setIsOpenChooseAPlan(false)
                    } else {
                      setIsOpenChooseAPlan(false)
                      setIsOpenPayment(true)
                      setTimeout(() => {
                        setupPreOpenPaddle()
                      })
                    }
                    }}
                    onNavigate={() => {
                    setIsOpenPayment(false)
                    }}
                    planDetail={planDetail}
                    planActive={planActive}
                    setPlanActive={setPlanActive}
                />
            )}
            {/* right */}
            <div
              style={{
                width: '50%',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: isOpenPayment ? '72px' : 0,
                background: 'rgba(249, 249, 249, 1)',
              }}
            >
              {!isOpenPayment ? (
                <video
                  width="100%"
                  height="100%"
                  style={{
                    objectFit: 'cover',
                  }}
                  loop
                  autoPlay
                  muted
                  playsInline
                  src={openModalTryPremium.video ? openModalTryPremium.video : VideoIntroRemove}
                  poster={premiumPoster}
                >
                  {/* <source src={openModalTryPremium.video ? openModalTryPremium.video : VideoIntroRemove} type="video/mp4" /> */}
                </video>
              ) : (
                <ContentForPlanV3 planActive={planActive} planDetail={planDetail} />
              )}
            </div>
            {/* clsoe icon */}
            <div
              style={{
                position: 'absolute',
                top: '16px',
                right: '16px',
                width: '32px',
                height: '32px',
                borderRadius: '50%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={e => {
                e.stopPropagation()
                setPlanActive(2)
                dispatch(
                  setOpenModalTryPremium({
                    isOpen: false,
                    source: null,
                    opened: openModalTryPremium.opened
                  })
                )
                setIsOpenPayment(false)
                handleOpenTutorial()
              }}
            >
              <Icons.WhiteCloseIcon size={24} />
            </div>
          </>
        ) : (
          <TryPremiumSuccess
            close={() => {
              if (openModalTryPremium.source) {
                if (user.userIsPremium) {
                  if (openModalTryPremium.callback) {
                    openModalTryPremium.callback()
                  }
                }
              }
              dispatch(
                setOpenModalTryPremium({
                  ...openModalTryPremium,
                  source: null,
                  callback: null,
                  isOpen: false,
                })
              )
              setIsOpenPayment(false)
              handleOpenTutorial()
            }}
          />
        )}
      </div>
    </>
  )
}

export default ModalTryPremiumV3

function TryPremiumSuccess({ close }) {
  const { t } = useTranslation()
  return (
    <>
      {/* left */}
      <div
        style={{
          padding: '48px 48px 16px 48px',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'left',
          width: '50%',
        }}
      >
        <h2 className="linear-gradient-text">{t('Congrats!')}</h2>
        <h3 style={{ margin: 0, marginTop: '8px', ...lightTheme.typography.Header26bold }}>
          {t('You’re now Premium.')}
        </h3>
        <h4
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
            marginBottom: 0,
            marginTop: '24px',
            ...lightTheme.typography.Small14regular,
          }}
        >
          {t('Time to explore your new design superpowers.')}
          <br />
          {t('Here are a few ideas on what to do next:')}
        </h4>
        <h4
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
            marginBottom: 0,
            marginTop: '24px',
            ...lightTheme.typography.Small14regular,
          }}
        >
          <Icons.CheckIcon fill="#FF0560" /> {t('Design your new profile pic')}
        </h4>
        <h4
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
            marginBottom: 0,
            marginTop: '20px',
            ...lightTheme.typography.Small14regular,
          }}
        >
          <Icons.CheckIcon fill="#FF0560" />
          {t('Create a logo for your business')}
        </h4>
        <h4
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
            marginBottom: 0,
            marginTop: '20px',
            ...lightTheme.typography.Small14regular,
          }}
        >
          <Icons.CheckIcon fill="#FF0560" />
          {t('Prepare multiple product photos')}
        </h4>
        <h4
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
            marginBottom: 0,
            marginTop: '20px',
            ...lightTheme.typography.Small14regular,
          }}
        >
          <Icons.CheckIcon fill="#FF0560" />
          {t('Make an event invitation')}
        </h4>
        <h4
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '8px',
            marginBottom: 0,
            marginTop: '20px',
            ...lightTheme.typography.Small14regular,
          }}
        >
          <Icons.CheckIcon fill="#FF0560" /> {t('Sync across all your devices')}
        </h4>

        <div style={{ marginTop: 'auto', textAlign: 'center' }}>
          <ButtonCustom
            type={SizeButton.LARGE}
            kind={KIND.primary}
            style={{
              position: 'relative',
              ...lightTheme.typography.Small14Semibold,
              color: '#fff',
              marginBottom: '32px',
              marginTop: '8px',
            }}
            onClick={() => close()}
          >
            {t("Let's Go!")}
          </ButtonCustom>
        </div>
      </div>
      {/* right */}
      <div style={{ width: '50%', position: 'relative' }}>
        <img src={BgTryPremiumSuccess} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        <div
          style={{
            position: 'absolute',
            top: '16px',
            right: '16px',
            width: '32px',
            height: '32px',
            borderRadius: '50%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => close()}
        >
          <Icons.WhiteCloseIcon size={24} />
        </div>
      </div>
    </>
  )
}

function PaymentInfo({ setIsOpenChooseAPlan }) {

  useEffect(() => {
    customAmplitude('Premium Payment info', {
      type: 'Metsikon'
    })
  }, [])
  
  return (
    <WrapAutoScroll
      style={{
        position: 'relative',
        padding: '48px',
        paddingRight: '32px',
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        textAlign: 'left',
        overflowY: 'scroll',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'start', height: '32px', gap: '8px' }}>
        <ButtonCustom
          shape={SHAPE.square}
          kind={KIND.minimal}
          type={SizeButton.SMALL}
          onClick={() => {
            // setIsOpenPayment(false)
            setIsOpenChooseAPlan(true)
          }}
        >
          <Icons.Back size={24} fill={lightTheme.colors.blackGray} />
        </ButtonCustom>
        <span style={{ ...lightTheme.typography.Header26bold }}>{t('Payment info')}</span>
      </div>
      <div
        id="paddle-checkout-loader"
        style={{
          display: 'none',
          position: 'absolute',
          bottom: '50%',
          left: '50%',
          transform: 'translate(-50%, 0%)',
          paddingLeft: '12px',
        }}
      >
        <Loading fill="#BCBCBC" />
      </div>
      <section id="paddle-checkout" className="paddle-checkout"></section>
    </WrapAutoScroll>
  )
}