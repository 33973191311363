import { customAmplitude } from "./customAmplitude";

export class nsfwManager {
  // Primary NSFW terms list
  private static readonly nsfwTerms: Set<string> = new Set([
    "phallus", "crotch", "sexy female", "dick", "skimpy", "girth", "thick", "honkers",
    "vagina", "hooters", "veiny", "knob", "seductress", "shaft", "orgy", "shag",
    "making love", "dominatrix", "bdsm", "thot", "horny", "bodily fluids", "smut",
    "bondage", "hardcore", "brothel", "incest", "succubus", "sensual", "dog collar",
    "fatherfucker", "xxx", "jerk off king at pic", "pleasures", "bimbo", "slavegirl",
    "twerk", "pleasure", "ahegao", "hentai", "jav", "legs spread", "wincest",
    "transparent", "sultry", "seducing", "sexy", "frigger", "rule34", "shibari",
    "horseshit", "seductive", "playboy", "fuck", "boudoir", "erotic seductive",
    "ballgag", "pinup", "voluptuous", "kinbaku", "holy shit", "naughty", "hell",
    "organs", "ass", "penis", "massive chests", "breasts", "bosom", "clunge",
    "busty", "nipple", "badonkers", "minge", "mommy milker", "ovaries", "booty",
    "labia", "big ass", "booba", "arse", "mammaries", "oppai", "human centipede",
    "bloodshot", "car crash", "bloody", "infected", "cutting", "corpse", "crucifixion",
    "infested", "cannibal", "crucified", "sadist", "surgery", "decapitate", "slaughter",
    "cannibalism", "visceral", "vivisection", "khorne", "cronenberg", "guts", "teratoma",
    "killing", "gory", "suicide", "hemoglobin", "bloodbath", "gruesome", "tryphophobia",
    "massacre", "flesh", "bruises", "blood", "kill", "wound", "taboo", "fascist",
    "nazi", "prophet mohammed", "slave", "coon", "honkey", "arrested", "jail",
    "handcuffs", "torture", "disturbing", "farts", "fart", "poop", "warts",
    "xi jinping", "shit", "erect", "big black", "brown pudding", "bunghole",
    "vomit", "sperm", "plebeian", "sensored", "censored", "uncouth",
    "silenced", "deepfake", "inappropriate", "pus", "waifu", "mp5", "1488",
    "no clothes", "speedo", "au naturale", "no shirt", "bare chest", "nude",
    "nudist", "nudity", "sex",
    "barely dressed", "bra", "risqué", "scantily", "clad", "cleavage",
    "stripped", "full frontal unclothed", "invisible clothes", "wearing nothing",
    "lingerie with no shirt", "naked", "without clothes on", "negligee", "zero clothes",
    "drugs", "cocaine", "heroin", "meth", "crack",
    
    // Additional NSFW terms
    "boob", "boobs", "nips", "butt", "buttocks", "tits", "tit", "genitals",
    "pubes", "pubic hair", "ejaculate", "ejaculation", "lust", "lustful",
    "seduce", "seduction", "adult content", "softcore", "bust",
    "strip", "stripping", "thicc", "kinky", "kink", "hot stuff",
    "raunchy", "provocative", "flirt", "flirty",
    "moan", "moaning", "naughty thoughts", "naughty pics", "penetrate",
    "penetration", "explicit", "explicit content", "orgasm", "porn", "porno",
    "pornographic", "raunchy pics", "raunchy videos"
  ]);

  // Leetspeak variations
  private static readonly leetspeakVariations: Map<string, string[]> = new Map([
    ["sex", ["s3x", "s*x", "s.ex"]],
    ["sexy", ["s3xy", "s*xy", "s.exy"]],
    ["horny", ["h0rny", "h*rny", "h.orny"]],
    ["fuck", ["f*ck", "f.ck", "phuck"]],
    ["dick", ["d*ck", "d.ck", "d!ck"]],
    ["ass", ["a$s", "a55", "@ss"]]
  ]);


  public static isSafeText(text: string, source: string): boolean {
    const lowercaseText = text.trim().toLowerCase();

    // Check exact matches with word boundaries
    for (const term of this.nsfwTerms) {
      const pattern = new RegExp(`\\b${term}\\b`, 'i');
      if (pattern.test(lowercaseText)) {
        
        customAmplitude('NSFW Content detected:', { source, text: lowercaseText })
        return false;
      }
    }

    // Check for leetspeak variations
    for (const [_, variations] of this.leetspeakVariations) {
      for (const variation of variations) {
        if (lowercaseText.includes(variation)) {
          customAmplitude('NSFW Content detected:', { source, text: lowercaseText })
          return false;
        }
      }
    }

    return true;
  }
}