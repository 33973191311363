import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="#111"
      strokeWidth={1.778}
      d="M3 9.4c0-2.24 0-3.36.436-4.216a4 4 0 0 1 1.748-1.748C6.04 3 7.16 3 9.4 3h4.978c2.24 0 3.36 0 4.216.436a4 4 0 0 1 1.748 1.748c.436.856.436 1.976.436 4.216v4.978c0 2.24 0 3.36-.436 4.216a4 4 0 0 1-1.748 1.748c-.856.436-1.976.436-4.216.436H9.4c-2.24 0-3.36 0-4.216-.436a4 4 0 0 1-1.748-1.748C3 17.738 3 16.618 3 14.378V9.4Z"
    />
    <path
      fill="#111"
      d="M9.457 17.094c-.384-2.778-.286-2.778-3.329-3.328a.373.373 0 0 1-.232-.116.355.355 0 0 1-.005-.478.356.356 0 0 1 .237-.112c3.043-.406 2.955-.498 3.329-3.318a.342.342 0 0 1 .107-.244.36.36 0 0 1 .579.12.34.34 0 0 1 .025.134c.351 2.778.4 2.768 3.304 3.308.09.007.174.048.235.114.06.065.094.15.093.24 0 .208-.14.319-.374.352-2.88.457-2.909.53-3.261 3.307a.344.344 0 0 1-.108.229.362.362 0 0 1-.482.014.345.345 0 0 1-.122-.222h.004ZM14.542 12.17c-.287-2.083-.214-2.083-2.496-2.495a.28.28 0 0 1-.174-.087.266.266 0 0 1-.004-.359c.047-.05.11-.08.178-.084 2.282-.304 2.216-.374 2.496-2.489a.257.257 0 0 1 .081-.182.27.27 0 0 1 .434.09c.013.031.02.065.02.1.262 2.083.3 2.076 2.477 2.481.068.006.13.036.177.085.045.05.07.113.069.18 0 .157-.104.24-.28.265-2.16.342-2.182.397-2.446 2.48a.258.258 0 0 1-.081.171.271.271 0 0 1-.453-.156h.002Z"
    />
  </svg>
)
export default SvgComponent
