import React, { useState } from 'react';
import { useEffect, useRef } from 'react';

import InspectorPanel from './InspectorPanel'
import AnimatedInspectorWrapper from './AnimatedInspectorWrapper';
import { ObjectType } from '@/scenes/engine/common/constants';
// Creation page callback
import { useAppDispatch } from '@store/store'
import InspectorContent from './InspectorContent';
import { useInspector, InspectorPanelType, InspectorPanelInputData } from './InspectorDataProvider';

interface InspectorProviderProps {
    activeObject: fabric.Object,
    setIsOpenInspector: (value: boolean) => void,
    isOpenInspector: boolean,
    isDisabled: boolean,
    pixelManipulationDoneClick: ()=>void,
    pixelManipulationCancelClick: ()=>void,
    didPixelManipulationPerformAction: boolean,
    isPixelManipulationInProcess: boolean
}

const InspectorProvider: React.FC<InspectorProviderProps> = ({ children, activeObject, setIsOpenInspector, isOpenInspector, isDisabled, pixelManipulationDoneClick, pixelManipulationCancelClick, didPixelManipulationPerformAction, isPixelManipulationInProcess }) => {
  const prevActiveObjectTypeRef = useRef<string | null>(null);
  const dispatch = useAppDispatch()
  const {panelsData, addInspector, hideInspector, removeInspectorInternal} = useInspector()

    useEffect(() => {
        dispatch(()=>{
            let animateChange = true;
            const activeObjectType = activeObject?.type || null;
        
            if (activeObjectType) {
                const prevType = prevActiveObjectTypeRef.current;
        
                // Determine if animation should be applied
                if (prevType !== activeObjectType) {
                    // Disable animation if switching between different active object types
                    // or if there's no prevType but inner panes are open. For example: canvas and resize are open
                    // in that case we close all panels and open without animation
                    if (prevType && activeObjectType || (!prevType && panelsData.length > 1)) {
                        animateChange = false;
                    }
        
                    // Update the ref with the current type
                    prevActiveObjectTypeRef.current = activeObjectType;
                }
                else {
                    // Re-open last inspector
                    const lastOpenedInspectorType = panelsData[panelsData.length - 1].inspectorType
                    hideInspector(lastOpenedInspectorType, false);
                    if (lastOpenedInspectorType != InspectorPanelType.MagicBg) {
                        addInspector(lastOpenedInspectorType, getParamsOnActiveObjectChange(lastOpenedInspectorType), false);
                    }
                    return
                }
        
                // Function to open the required inspector
                const openActiveObjectInspector = (type: InspectorPanelType) => {
                    // Open the relevant inspector with or without animation
                    addInspector(type, {}, animateChange);
        
                    // Hide all other inspectors except Canvas and the one just opened
                    panelsData.forEach((panel) => {
                        if (panel.inspectorType !== InspectorPanelType.Canvas && panel.inspectorType !== type) {
                            hideInspector(panel.inspectorType, false);
                        }
                    });
                };
        
                // Open the appropriate inspector based on the active object type
                console.log(activeObjectType)
                switch (activeObjectType) {
                    case ObjectType.BAZAART_SHAP:
                    case ObjectType.BAZAART_STICKER:
                        openActiveObjectInspector(InspectorPanelType.Sticker);
                        break;
                    case ObjectType.BAZAART_TEXT:
                        openActiveObjectInspector(InspectorPanelType.Text);
                        break;
                    case ObjectType.BAZAART_IMAGE:
                        openActiveObjectInspector(InspectorPanelType.Image);
                        break;
                    case ObjectType.ACTIVE_SELECTION:
                        openActiveObjectInspector(InspectorPanelType.MultiElement);
                        break;
                    case ObjectType.GROUP:
                        openActiveObjectInspector(InspectorPanelType.MultiElement);
                        break;
                    default:
                        console.log(`No inspector available for the object type: ${activeObjectType}`);
                        break;
                }
            } else {
                // If there's no active object, hide all inspectors except Canvas
                prevActiveObjectTypeRef.current = null;
                for (let index = panelsData.length - 1; index > 0; index--) {
                    const type = panelsData.at(index).inspectorType
                    if (type != InspectorPanelType.Canvas) {
                        hideInspector(type, true);
                    }
                }
            }
        })
    }, [activeObject]);

    const getParamsOnActiveObjectChange = (type: InspectorPanelType): InspectorPanelInputData => {
        if (type === InspectorPanelType.Adjust) {
            return { object: activeObject }
        }
        else if (type === InspectorPanelType.Filter) {
            return { object: activeObject }
        }
        return {}
    }
                

  const isOtherInspectorPanelOnTop = (index: number) => {
    return panelsData.slice(index + 1).some(panel => panel.isVisible)
  }
  
  return (
    <>
        <div style={isDisabled ? {zIndex: 5, opacity: 0.5} : { display: "none"}}><InspectorPanel title=""></InspectorPanel></div>
        { panelsData.map((panelData, index) => {
            return <AnimatedInspectorWrapper
                key={panelData.inspectorType}
                isVisible={panelData.isVisible}
                onClose={() => removeInspectorInternal(panelData.inspectorType)}
                addWithAnimation={panelData.addWithAnimation}
                closeWithAnimation={panelData.closeWithAnimation}
                isInspectorPanelOnTop={!isOtherInspectorPanelOnTop(index)}
            >
                <InspectorContent
                    type={panelData.inspectorType}
                    data={panelData.inputData}
                    hideInspector={hideInspector}
                    pixelManipulationDoneClick={pixelManipulationDoneClick}
                    pixelManipulationCancelClick={pixelManipulationCancelClick}
                    didPixelManipulationPerformAction={didPixelManipulationPerformAction}
                    isPixelManipulationInProcess={isPixelManipulationInProcess}
                    />
            </AnimatedInspectorWrapper>
        })}
        {children}
   </>
  );
};

export { InspectorProvider, InspectorPanelType }
