import { useEffect, useState } from 'react'
import { useEditorContext } from '@/scenes/engine'
import Download from '../Icons/Download'
import { useParams } from 'react-router'
import { KIND } from 'baseui/button'
import { selectTemplateConfig, selectBackgroundActive } from '@/store/slices/templates/selectors'
import { useSelector } from 'react-redux'
import useAppContext from '@/hooks/useAppContext'
import { selectOpenModalTryPremium, selectUser, selectUserIsPremium } from '@/store/slices/user/selectors'
import store, { useAppDispatch } from '@/store/store'
import { customAmplitude } from '@/utils/customAmplitude'
import { useTranslation } from 'react-i18next'
import { PLACEMENT, StatefulPopover } from 'baseui/popover'
import ButtonCustom from '@/components/ButtonCustom'
import { SizeButton } from '@/constants/sizeButton'
import HeadingInspector from '@/components/HeadingInspector'
import Icons from '../Icons'
import { lightTheme } from '@/customTheme'
import { setOpenModalTryPremium } from '@/store/slices/user/actions'
import Loading from '@/components/Loading'
import { useLocation } from 'react-router-dom'
import { SHOW_DOWNLOAD_APP_TIME_DURATION, ShowModalType } from '@/scenes/engine/common/constants'
import { displayConfetti } from '@/utils/confettiAnimation'
import AutoScroll from '@/components/AutoScroll'
import { Block } from 'baseui/block'
import { jsPDF } from "jspdf";
import emptyFramBG from '@assets/images/empty_frame_bg.svg'

const OPTIONS_TYPE = [
  {
    id: 1,
    title: 'jpeg',
    desc: 'No transparency. High quality. Files are generally small.',
  },
  {
    id: 2,
    title: 'png',
    desc: 'Supports transparency. Best quality. Files can be big.',
  },
  {
    id: 3,
    title: 'webp',
    desc: 'Supports transparency. Supreme quality. Files are smallest.',
  },
  {
    id: 4,
    title: 'pdf',
    desc: 'Best for documents.',
  },
]

function ExportImage() {
  const [isOpen, setIsOpen] = useState(false)
  const {editor, canvas} = useEditorContext()
  const [previewImage, setPreviewImage] = useState<any>(null)
  const [isLoadingPreviewImage, setIsLoadingPreviewImage] = useState(false)
  const { id } = useParams<{ id: string }>()
  const location = useLocation();

  const [valueSelected, setValueSelected] = useState<{ id: number; title: string, desc: string}>({id: 1, title: 'jpeg', desc: 'No transparency. High quality. Files are generally small.'})
  const [isOpenMenu, setIsOpenMenu] = useState(false)


  const hasPremium = useSelector(selectUserIsPremium)

  const templateConfig = useSelector(selectTemplateConfig)
  const backgroundActive = useSelector(selectBackgroundActive)

  const { isOpenPixelManipulationObject, setShowModalType, showModalType, disableEditorState } = useAppContext()

  const user = useSelector(selectUser)
  const dispatch = useAppDispatch()
  const [isOpenPopover, setIsOpenPopover] = useState(false)
  const openModalTryPremium = useSelector(selectOpenModalTryPremium)

  useEffect(() => {
    if (isOpen && editor) {
      console.log('TODO: PreviewTemplate exportToJSON')
      // TODO : exportToJSON
      // const template = editor.exportToJSON()
      // const keys = template.objects.map(object => {
      //   return object.metadata && object.metadata.keys ? object.metadata.keys : []
      // })

      // const params: Record<string, string> = {}
      // const uniqElements = uniq(flatten(keys))
      // uniqElements.forEach(key => {
      //   params[key] = ''
      // })

      // setOptions(params)
      // if (uniqElements.length === 0) {
      //   handleBuildImage()
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, editor])

  useEffect(() => {
    if(!editor) return
    function setFormat() {
      const frame = editor.handlers.frameHandler.get()
      let bgImage = editor.handlers.frameHandler.getBackgroundImage()
      const hasTransparentBg = frame.fill && !(typeof frame.fill  === 'string') && !bgImage
      if(hasTransparentBg) {
        setValueSelected({
          id: 2,
          title: 'png',
          desc: 'Supports transparency. Best quality. Files can be big.',
        })
      } else {
        setValueSelected({id: 1, title: 'jpeg', desc: 'No transparency. High quality. Files are generally small.'})
      }
    }

    setFormat()

    editor.on('frame:transparent', setFormat)

    return () => {
      editor.off('frame:transparent', setFormat)
    }
  }, [editor, isLoadingPreviewImage])

  const cropCanvas = (image, newX, newY, newWidth, newHeight) => {
    var croppedImage = document.createElement('canvas')
    const ctx = croppedImage.getContext('2d')

    //set the canvas size to the new width and height
    croppedImage.width = newWidth
    croppedImage.height = newHeight

    //draw the image
    ctx.drawImage(image, newX, newY, newWidth, newHeight, 0, 0, newWidth, newHeight)
    return croppedImage
  }

  const handleBuildImage = async (format) => {
    // TODO: make export by this flow:
    const image = await editor.exportPNG(false, format === 'pdf' ? 'png' : format)
    return image

    // const frame = editor.handlers.frameHandler.get()

    // // let frameRect = frame.getBoundingRect()

    // const c = document.getElementById('canvas')

    // let guid = nanoid()

    // //frame.width * 0.1 / Watermark.
    // let watermarkUrl = new URL(Watermark)
    // await MediaImageRepository.getInstance().storeImageUrl(guid, MediaImageType.latest, watermarkUrl);

    // const watermarkOption = {
    //   id: guid,
    //   type: ObjectType.BAZAART_STICKER,
    //   centerPoint: {
    //     x: 0.88,
    //     y: 0.97,
    //   },
    //   sizeOnCanvas: {
    //     width: 0.2,
    //   },
    //   transformation: {
    //     horizontalFlip: false,
    //     verticalFlip: false,
    //   },
    //   boundingBox : {y: 0, width: 1, height: 1, x: 0},
    //   absoluteRotation: 0,
    //   bazaartGuid: guid,
    // }
    // await editor.handlers.objectsHandler.add(watermarkOption)

    // // editor.handlers.objectsHandler.scale("fit")
    // // editor.handlers.objectsHandler.update({
    // //   scaleX:0.1,
    // //   scaleY:0.1,
    // // })
    // // editor.handlers.objectsHandler.alignBottom()
    // // editor.handlers.objectsHandler.alignRight()
    // var cloneZoomRatio = zoomRatio

    // editor.handlers.zoomHandler.zoomToFit()
    // let frameRect = frame.getBoundingRect()

    // editor.handlers.objectsHandler.deselect()

    // // we need to wait zoom will be fit
    // await new Promise(r => setTimeout(r, 500))

    // Object.keys(frameRect).map(function (key, index) {
    //   frameRect[key] *= window.devicePixelRatio
    // })

    // let coppedCanvas = cropCanvas(c, frameRect.left, frameRect.top, frameRect.width, frameRect.height)
    // let image = coppedCanvas.toDataURL('image/png')

    // editor.handlers.objectsHandler.removeById(watermarkOption.id)
    // editor.handlers.zoomHandler.zoomToRatio(cloneZoomRatio)

    // return image
  }

  const GAReportDownload = async () => {
    let template_id = templateConfig?.templateId
      ? templateConfig?.templateId
      : Number(id) !== 6764
        ? Number(id)
        : undefined

    const eventProperties = {
      'Number of Layers': editor.handlers.objectsHandler.getLayers().length,
      'Watermark': !store.getState().editor.user.userIsPremium,
      'Template': !!template_id,
      'Template ID': templateConfig?.templateId ? templateConfig?.templateId : template_id,
      'Template Category': templateConfig?.templateCategory ?? 'none',
      // @ts-ignore
      'layer_type_stock': editor.handlers.objectsHandler.getLayers().some(x => x.isStockImage),
      // @ts-ignore
      'layer_type_magic_background':editor.handlers.objectsHandler.getLayers().some(x=>x.isMagicBackgroundLayer),
      // @ts-ignore
      'finger': editor.handlers.objectsHandler.getLayers().some(x => x.finger) ? 1 : 0,
      Format: valueSelected.title,
    }
    if (backgroundActive) {
      eventProperties['Background ID'] = backgroundActive.id
      eventProperties['Background Category'] = backgroundActive.category
    }
    customAmplitude('Image Shared', eventProperties)

    // @ts-ignore
    window.dataLayer.push({ event: 'download_click', ...eventProperties })
  }

  const createAndDownloadImage = async () => {
    GAReportDownload()
    let image = await handleBuildImage(valueSelected.title)
    const a = document.createElement('a')
    a.href = image
    a.download = 'Bazaart'
    a.click()
  }

  const { t } = useTranslation()
  const createAndSetPreviewImage = async () => {
    setIsLoadingPreviewImage(true)
    let image = await handleBuildImage(valueSelected.title)
    setPreviewImage(image)
    setIsLoadingPreviewImage(false)
  }

  const downloadFromPreviewModal = async () => {
    GAReportDownload()
    const a = document.createElement('a')
    let image = await handleBuildImage(valueSelected.title)
    if(valueSelected.title === 'pdf') {
      const frame = editor.handlers.frameHandler.get()
      const pdf = new jsPDF({
        orientation: "landscape", // or 'portrait'
        unit: "px",               // Units (px, mm, cm, in)
        format: [frame.width, frame.height]
      });
      pdf.addImage(image, "PNG", 0, 0, frame.width, frame.height);
      pdf.save("Bazaart.pdf");
      return
    }
    a.href = image
    a.download = 'Bazaart'
    a.click()
  }

  if (location.pathname.includes('create')) {
    return null
  }

  const handleDisplayConfentti = () => {
    const isDisplayedModalDownloadApp = localStorage.getItem('isDisplayedModalDownloadApp')
    if(isDisplayedModalDownloadApp) {
      return
    }
    customAmplitude('Get App Modal', { Source: '1st time' })
    displayConfetti()
    setTimeout(() => {
      setShowModalType(ShowModalType.DOWNLOAD_APP)
    }, SHOW_DOWNLOAD_APP_TIME_DURATION)
  }

  return (
    <StatefulPopover
      content={({ close }) => (
        <div
          style={{
            width: '450px',
            height: hasPremium ? '623px' : '687px',
            background: '#fff',
            borderRadius: '16px',
          }}
        >
          <HeadingInspector
            title={t('Download')}
            hasNavigation={false}
            hasClose={true}
            handleClose={close}
            style={{ height: '72px', paddingTop: '24px', paddingBottom: '16px' }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '305px',
              padding: '16px',
              background: 'rgba(249, 249, 249, 1)',
            }}
          >
            {isLoadingPreviewImage ? (
              <Loading />
            ) : (
              <img style={{ objectFit: 'contain', maxWidth: '418px', maxHeight: '273px', backgroundImage: `url(${emptyFramBG})` }} alt="" src={previewImage} />
            )}
          </div>
          <div style={{ padding: '12px 24px 24px' }}>
            <p
              style={{
                ...lightTheme.typography.Small11medium,
                color: '#666666',
                margin: '0 0 32px 0',
                textAlign: 'center',
              }}
            >
          {`${editor.handlers.frameHandler.get()?.width} ${t('X')} ${editor.handlers.frameHandler.get()?.height} ${t('px')}`}
      </p>
            {/*  */}
            <p
              style={{
                ...lightTheme.typography.Small14Bold,
                color: lightTheme.colors.blackGray,
                margin: '0 0 8px 0',
              }}
            >
              {t('Save format')}
            </p>
            <StatefulPopover
              placement={PLACEMENT.bottom}
              overrides={{
                Body: {
                  style: ({ $theme }) => ({
                    marginTop: '4px',
                    zIndex: '100',
                    transitionDuration: 'none',
                  }),
                },
                Inner: {
                  style: ({ $theme }) => ({
                    transitionDuration: 'none',
                  }),
                },
              }}
              content={({ close: closeOptions }) => (
                <AutoScroll
                  style={{
                    padding: '16px 8px 16px 16px',
                    borderRadius: '16px',
                    width: '402px',
                    background: '#fff',
                  }}
                >
                  {OPTIONS_TYPE.map((opt, index) => (
                    <Block
                      onClick={() => {
                        if(opt.id === 4 && !hasPremium) {
                          dispatch(setOpenModalTryPremium({
                            isOpen: true,
                            source: 'BtSubscriptionPDF',
                            callback: () => setValueSelected(opt),
                          }))
                          const eventProperties = {
                            Source: 'BtSubscriptionPDF',
                            Type: 'Standard',
                          }
                          customAmplitude('Premium Prompt', eventProperties)
                          // close()
                        } else {
                          setValueSelected(opt)
                        }
                        closeOptions()
                      }}
                      $style={{
                        height: 'wrap-content',
                        cursor: 'pointer',
                        padding: '8px',
                        borderRadius: '8px',
                        display: 'flex ',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: '8px',
                        position: 'relative !important',
                        ':hover': {
                          background: '#F2F2F2',
                        },
                        ':active': {
                          background: lightTheme.colors.grayScale100,
                        },
                      }}
                      key={index}
                    >
                      <div style={{ flex:'1', display: 'flex', flexDirection: 'column'}}>
                        <p style={{ ...lightTheme.typography.Small14regular, margin: 0, textTransform: 'uppercase' }}>
                          {t(opt.title)}
                        </p>
                        <p style={{ ...lightTheme.typography.Small11medium, margin: 0, lineHeight: '20px', color: lightTheme.colors.grayScale400 }}>
                          {t(opt.desc)}
                        </p>
                      </div>
                      <div
                        style={{
                          width: '24px',
                          height: '24px',
                          top: 0,
                          bottom: 0,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          alignSelf: 'center',
                          flexShrink: 0,
                        }}
                      >
                        {valueSelected.id === opt.id && (
                          <Icons.CheckIcon fill="#FF0560" />
                        )}
                        {opt.id === 4 && !hasPremium && (
                          <Icons.PremiumIcon/>
                        )}
                      </div>
                      
                    </Block>
                  ))}
                </AutoScroll>
              )}
              onOpen={() => setIsOpenMenu(true)}
              onClose={() => setIsOpenMenu(false)}
            >
              {isOpenMenu ? (
                <div>
                  <ButtonCustom
                    endEnhancer={<Icons.Drop />}
                    type={SizeButton.LARGE}
                    kind={KIND.tertiary}
                    style={{
                      ...lightTheme.typography.Small14regular,
                      fontWeight: 400,
                      justifyContent: 'space-between',
                      background: '#fff!important',
                      borderColor: '#B2B2B2!important',
                      textTransform: 'uppercase'
                    }}
                  >
                    {valueSelected.title}
                  </ButtonCustom>
                </div>
              ) : (
                <ButtonCustom
                  endEnhancer={<Icons.Drop />}
                  type={SizeButton.LARGE}
                  kind={KIND.tertiary}
                  style={{
                    ...lightTheme.typography.Small14regular,
                    fontWeight: 400,
                    justifyContent: 'space-between',
                    ':hover': {
                      background: '#fff!important',
                      borderColor: '#B2B2B2!important',
                    },
                    ':active': {
                      background: '#fff!important',
                      borderColor: '#B2B2B2!important',
                    },
                    textTransform: 'uppercase'
                  }}
                >
                  {valueSelected.title}
                </ButtonCustom>
              )}
            </StatefulPopover>
            {/*  */}
            <ButtonCustom
              type={SizeButton.LARGE}
              kind={KIND.primary}
              startEnhancer={<Download size={24} fill="#fff" />}
              style={{ marginTop: '32px' }}
              onClick={() => {
                handleDisplayConfentti()
                downloadFromPreviewModal()
                close()
              }}
            >
              {t('Download')}
            </ButtonCustom>
            {!hasPremium ? (
              <ButtonCustom
                type={SizeButton.LARGE}
                kind={KIND.secondary}
                startEnhancer={<Icons.PremiumIcon />}
                onClick={() => {
                  dispatch(
                    setOpenModalTryPremium({
                      isOpen: true,
                      source: 'BtSubscriptionWatermark',
                      callback: () => createAndDownloadImage(),
                    })
                  )
                  const eventProperties = {
                    Source: 'BtSubscriptionWatermark',
                  }
                  customAmplitude('Premium Prompt', eventProperties)
                }}
                style={{ marginTop: '16px' }}
              >
                {t('Remove watermark')}
              </ButtonCustom>
            ) : null}
          </div>
        </div>
      )}
      triggerType="click"
      returnFocus={false}
      autoFocus={false}
      placement="bottomRight"
      popoverMargin={4}
      overrides={{
        Body: {
          style: ({ $theme }) => ({
            zIndex: 100,
          }),
        },
      }}
      stateReducer={(a, b, c) => {
        setIsOpenPopover(b.isOpen)
        return b
      }}
      dismissOnClickOutside={!(openModalTryPremium.isOpen || showModalType)}
    >
      <div style={{ pointerEvents: isOpenPopover ? 'none' : 'auto' }}>
        <ButtonCustom
          type={SizeButton.STANDARD}
          kind={KIND.primary}
          disabled={isOpenPixelManipulationObject || disableEditorState.disableNavbar}
          onClick={e => {
            // if (hasPremium) {
            //   handleDisplayConfentti()
            //   e.preventDefault()
            //   e.stopPropagation()
            //   createAndDownloadImage()
            // } else {
            //   createAndSetPreviewImage()
            // }

            createAndSetPreviewImage()
          }}
          style={{
            display: 'flex',
            width: 'fit-content',
            gap: '6px',
            background: isOpenPixelManipulationObject || disableEditorState.disableNavbar
              ? lightTheme.colors.grayScale50
              : isOpenPopover
              ? `${lightTheme.colors.primary100} !important`
              : 'auto',
          }}
        >
          <Download
            size={24}
            fill={isOpenPixelManipulationObject || disableEditorState.disableNavbar ? lightTheme.colors.grayScale200 : lightTheme.colors.white}
          />
          <span
            style={{
              fontSize: '14px',
              fontWeight: '600',
              lineHeight: '24px',
              letterSpacing: '-0.006em',
              color: isOpenPixelManipulationObject || disableEditorState.disableNavbar ? '#cccccc' : lightTheme.colors.white,
            }}
          >
            {t('Download')}
          </span>
        </ButtonCustom>
      </div>
    </StatefulPopover>
  )
}

export default ExportImage
