import { fabric } from 'fabric'
import { ObjectType } from '../../common/constants'
import objectToFabric from '../../utils/objectToFabric'
import RemoveBaseHandler from './RemoveBaseHandler'
import { customAmplitude } from '@/utils/customAmplitude'
import {FRAME_PADDING_ADD_MENU} from '../../common/constants'
import { Rectangle } from '@scenes/engine/objects/media-repository/rectangle'
import { loadImageFromURL } from '../../utils/image-loader'

export abstract class PixelManipulationObjectHandler extends RemoveBaseHandler {
  public clipboard
  public isCut
  public animDirection = 'down'
  public img
  public destinationWidth = 0
  public destinationHeight = 0
  public destinationX = 0
  public destinationY = 0

  // image info
  public startX = 0
  public startY = 0
  public startWidth = 0
  public startHeight = 0
  public scaleX = 1.0
  public scaleY = 1.0

  // drawing mask info
  public maskScaleX = 1.0
  public maskScaleY = 1.0

  public startDegree = 0
  public activeObject = null
  public frameMainCanvas = null
  public originalCanvas = null

  public duration = 800
  public startControlVisibility = null
  public isHandleAnimation = true
  
  translateX = 0
  translateY = 0

  protected scaleImageToScreen = 1.0
  protected imageResolution = 1280
  protected maskResolution = 1280

  protected readonly offsetTop = 64
  public readonly offsetLeft = -356 // actually extra room
  public readonly offsetPanelItem = 88
  
  public VERTICAL_PADDING = 144
  public HORIZONTAL_PADDING = 144

  aspectRatio = 1.0
  isEraserToolResize = true
  isZoomResize = true
  isOpen = false

  public add = async item => {
    this.root.transactionRemoveHandler.save()
    const object: fabric.Object = await objectToFabric.run(item, this.root.canvasRemoveHandler.canvas)
    object.lockScalingFlip = true
    this.canvas.add(object)
    object.setCoords()

    let type

    switch (object.type.slice(13)) {
      case 'image':
        type = 'photos'
        break
      case 'text':
        type = 'text'
        break

      case 'sticker':
        type = 'graphics'
        break
      default:
        type = 'photos'
    }

    const eventProperties = {
      Tool: `bazaart.add.${type}`,
      Type: object.type,
    }
    customAmplitude('Selected tool', eventProperties)

    this.canvas.setActiveObject(object)
    this.canvas.requestRenderAll()
  }

  async setupImage() {

  }

  addImageToCanvas = async () => {
    // override me
  }

  addNewImage = async (src) => {
    // override me
  }

  addChessBg = async () => {
    // override me
  }


  setupInitialPosition() {
    if (!this.activeObject) {
      return
    }
    this.startDegree = this.activeObject.angle
    this.startControlVisibility = Object.assign({}, this.activeObject._controlsVisibility);

    this.activeObject.rotate(0)
    this.activeObject.setCoords();
    let startRect = this.getStartRect()
    console.log('startRect: ', startRect)
    this.startWidth = startRect.width
    this.startHeight = startRect.height

    this.startX = startRect.x
    this.startY = startRect.y

    this.activeObject.rotate(this.startDegree)
    this.activeObject.setCoords(true);
  }

  getStartRect(): Rectangle {
    let boundingBox = this.activeObject.getBoundingRect()
    let rect = new Rectangle(
      boundingBox.left,
      boundingBox.top,
      boundingBox.width,
      boundingBox.height
    );
    return rect;
  }

  addEraserBackground(backgroundImage: string) {
    const eraserBackgroundElement = document.getElementById('eraser-background') as HTMLImageElement
    eraserBackgroundElement.src = backgroundImage
    eraserBackgroundElement.style.display = 'block'
    // @ts-ignore
    let canvasCenterElement = document.querySelector('.overlay-remove').style;
    eraserBackgroundElement.style.width = `${parseInt(canvasCenterElement.width)}px`
    eraserBackgroundElement.style.height = `${parseInt(canvasCenterElement.height)}px`
    eraserBackgroundElement.style.left = `${parseInt(canvasCenterElement.left)}px`
    eraserBackgroundElement.style.top = `${parseInt(canvasCenterElement.top)}px`
  }

  removeEraserBackground() {
    const eraserBackgroundElement = document.getElementById('eraser-background') as HTMLImageElement
    eraserBackgroundElement.src = ''
    eraserBackgroundElement.style.display = 'none'
  }

  calculateDestinationSize() {
    let maxEdge = Math.min(this.imageResolution, Math.max(this.img.height, this.img.width));

    if (this.aspectRatio <= 1.0) {
      this.canvas.setWidth(maxEdge).setHeight(maxEdge * this.aspectRatio)
      this.scaleImageToScreen = this.startWidth / maxEdge;
    } else {
      this.canvas.setWidth(maxEdge / this.aspectRatio).setHeight(maxEdge)
      this.scaleImageToScreen = this.startHeight / maxEdge;
    }
    this.calculateDestinationRatio()
  }

  addCursorPreview = (logicValue) => {
    // @ts-ignore
    // let canvasCenterElement = document.querySelector('.overlay-remove').style;
    // let startCanvasX = parseInt(canvasCenterElement.left) + parseInt(canvasCenterElement.width) / 2 - this.offsetLeft
    // let startCanvasY = parseInt(canvasCenterElement.top)+ parseInt(canvasCenterElement.height) / 2 - this.offsetTop
    let cursorPreview = document.querySelector('.custom-cursor') as HTMLElement;
    let wrapCanvasRemoveTool = document.getElementById('wrap-canvas-remove-tool')

    let cursorSize = logicValue
    cursorPreview.style.width = `${cursorSize}px`
    cursorPreview.style.height = `${cursorSize}px`
    cursorPreview.style.top = `${wrapCanvasRemoveTool.offsetHeight / 2 + wrapCanvasRemoveTool.scrollTop}px`
    cursorPreview.style.left = `${wrapCanvasRemoveTool.offsetWidth / 2 + this.offsetLeft / 2 + FRAME_PADDING_ADD_MENU / 2 + wrapCanvasRemoveTool.scrollLeft}px`
    cursorPreview.style.display = 'block'
  }

  hideCursorPreview = () => {
    const cursorPreview = document.querySelector('.custom-cursor') as HTMLElement;
    cursorPreview.style.display = 'none'
  }

  recenter = (isOpenInspector) => { 
    const containerElement = document.querySelector('#wrap-canvas-remove-tool .remove-container-class') as HTMLElement;
    const computedStyle = window.getComputedStyle(containerElement);
    const transformValue = computedStyle.transform;
    const matrixValues = transformValue.match(/matrix\((.+)\)/)?.[1].split(', ');
    const newTranslateX = isOpenInspector ? this.translateX : this.translateX + 134
    const updatedMatrixValues = matrixValues?.map((value, index) => (index === 4 ? newTranslateX.toString() : value));
    const updatedTransformValue = `matrix(${updatedMatrixValues?.join(', ')})`;

    // Update the containerElement's transform style
    containerElement.style.transform = updatedTransformValue;
  }

   handleRemoveToolResize() {
    this.calculateDestinationRatio()
    this.root.canvasRemoveHandler.setBrushSizeOnZoom()
  }

  getCenterPosition(containerElement) {
    let top = 0
    let left = 0
    let originalWidth = parseInt(containerElement.style.width);
    let originalHeight = parseInt(containerElement.style.height);
    const containerWidth = window.innerWidth + this.offsetLeft - 88
    const containerHeight = window.innerHeight - this.offsetTop
    this.VERTICAL_PADDING = window.innerHeight < 700 ? 64 : 144
    this.HORIZONTAL_PADDING = window.innerWidth < 700 ? 64 : 144
    if(window.innerHeight - this.offsetTop - this.destinationHeight === this.VERTICAL_PADDING) { 
      top = this.VERTICAL_PADDING / 2 -(originalHeight - originalHeight * this.scaleY) / 2
      left = (containerWidth / 2) + 88 - originalWidth / 2
    } else {
      top = (containerHeight / 2) - originalHeight / 2
      left = this.HORIZONTAL_PADDING / 2 -(originalWidth - originalWidth * this.scaleX) / 2
    }
    return {top, left}
  }

  calculateDestinationRatio() {
    let maxEdge = Math.min(this.imageResolution, Math.max(this.img.height, this.img.width));

    this.VERTICAL_PADDING = window.innerHeight < 700 ? 64 : 144
    this.HORIZONTAL_PADDING = window.innerWidth < 700 ? 64 : 144

    const maxHeight = window.innerHeight - this.VERTICAL_PADDING - this.offsetTop
    const maxWidth = window.innerWidth - this.HORIZONTAL_PADDING + this.offsetLeft - this.offsetPanelItem

    if ((maxEdge * this.aspectRatio) > maxHeight) {
      this.destinationWidth = maxHeight / this.aspectRatio;
      this.destinationHeight = maxHeight;
    } else if (maxEdge > maxWidth){
      this.destinationWidth = maxWidth;
      this.destinationHeight = maxWidth * this.aspectRatio;
    } else {
      if (maxWidth - this.canvas.width > maxHeight - this.canvas.height) {
        this.destinationWidth = Math.min(maxHeight / this.aspectRatio, maxWidth);
        this.destinationHeight = maxHeight;
      } else {
        this.destinationWidth = maxWidth;
        this.destinationHeight = Math.min(maxWidth * this.aspectRatio, maxHeight);
      }
    }
  }

  reset() {

  }

  protected resetActiveObjectState = () => {
    let wrapCanvasRemoveTool = document.getElementById('wrap-canvas-remove-tool')
    let bottomContainerElement = document.querySelector('.canvas-container') as HTMLElement
    let containerElement = document.querySelector('#wrap-canvas-remove-tool .remove-container-class') as HTMLElement

    this.activeObject.visible = true;
    this.activeObject.hasBorders = true;

    let defaultAllControlsEnabled = {
      bl: true,
      br: true,
      mb: true,
      ml: true,
      mr: true,
      mt: true,
      tl: true,
      tr: true,
      mtr: true
    }
    let controlsEnabled = Object.assign({}, defaultAllControlsEnabled, this.startControlVisibility)
    this.activeObject.setControlsVisibility(controlsEnabled);
    this.activeObject.canvas.renderAll()

    wrapCanvasRemoveTool.style.transition = ''
    bottomContainerElement.style.transition = ''
    containerElement.style.transition = ''
    this.root.transactionRemoveHandler.undos = []
    this.root.transactionRemoveHandler.redos = []
    const upperCanvas = document.querySelector('.remove-container-class .upper-canvas') as HTMLElement
    upperCanvas.style.transform = 'none'
    upperCanvas.style.transformOrigin = 'none'
    this.scaleX = 1.0
    this.scaleY = 1.0
    
    let scrollBarX = document.getElementById('scrollbarX')
    let scrollBarY = document.getElementById('scrollbarY')
    scrollBarX.style.zIndex = '1'
    scrollBarY.style.zIndex = '1'
    this.root.zoomRemoveHandler.minZoom = 1
    this.root.zoomRemoveHandler.maxZoom = 5
    this.root.zoomRemoveHandler.currentZoom = 1
    this.root.zoomRemoveHandler.prevZoom = 1
    this.root.zoomRemoveHandler.baseStep = 1
    this.root.zoomRemoveHandler.sliderStep = 1
    this.isEraserToolResize = true
    document.removeEventListener('visibilitychange', () => {})
  }

  public updatePositionFollowMainCanvas = () => {

  }
  abstract FinishToolState(): Promise<void> 

  isZoomAvaliable = () =>{
    return true
  }

  apply = () => {

  }

  discard = () => {

  }
  parseTranslateValues(str: string) {
    let translateX = 0;
    let translateY = 0;

    if (str) {
      const match = str.match(/-?\d+(\.\d+)?/g).map(Number);
      if (match) {
        translateX = match[0] || 0; // Parse X value, default to 0 if NaN
        translateY = match[1] || 0; // Parse Y value, default to 0 if NaN
      }
    }
    return [translateX, translateY]
  }
}