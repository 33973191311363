export const PROPERTIES_TO_INCLUDE = [
  'id',
  'name',
  'description',
  'src',
  'sizeOnCanvas',
  'boundingBox',
  'center',
  'transformation',
  'textProperties',
  'effects',
  'version',
  'blending',
  'absolutePositioned',
  'top',
  'left',
  'useBzrtBgMask',
  'fill',
  'layerAssetStateId',
  'bazaartGuid',
  'isLatest',
  'backgroundIdentifier',
  'filter',
  'filters',
  'filterIntensity',
  '_originalScaleX',
  '_originalScaleY',
  'sizeId',
  'scaleX',
  'scaleY',
  '_filterScalingX',
  '_filterScalingY',
  'evented',
  'cacheKey',
  'cacheCounter',
  'isTemplateLayer',
  'effects_from_template',
  'hasReplaceableLayer',
  'isStockImage',
  'centerPoint',
  'unit',
  'icon',
  'hasTransparency',
  'isMagicBackgroundLayer',
  'isIntializedNormalizeMask',
  'isIntializedNormalizedImage',
  'adjustments',
  'isResized',
  '_originalHeight',
  '_originalWidth',
  'hoverCursor',
  'source_platform',
]

export const PROPERTIES_TO_EXPORT = [
  'absoluteRotation',
  'adjustments',
  'audioVolume',
  'bazaartGuid',
  'boundingBox',
  // 'center', - we adding it manualy by copy from centerPoint
  'currentPrice',
  'effects',
  'filter',
  'filterIntensity',
  'hasFaceFeatures',
  'hasTransparency',
  'imageHeight',
  'imageWidth',
  'imagesFormat',
  'imagesLastUpdated',
  'imagesOrientation',
  'layerAssetStateId',
  'isDeleted',
  'isDirty',
  'isHidden',
  'isInitializing',
  'isLocked',
  'isPlaceholder',
  'isShopable',
  'isTemplateLayer',
  'itemType',
  'lastPrice',
  'opacity',
  'sizeOnCanvas',
  'state',
  'storeId',
  'thumbnailHeight',
  'thumbnailWidth',
  'transformation',
  'version',
  'zLayer',
  'backgroundIdentifier',
  'isAnimated',
  'isBackgroundImage',
  'startTime',
  '_endTime',
  'shouldUseMattedImage',
  'fullImgUrl',
  'hasPeople',
  'features',
  'textProperties',
  'sourceGraphicsItemName',
  'sourceGraphicsPackName',
  'sourceGraphicsType',
  'blending',
  'overlayType',
  'videoSpeed',
  'saliencyResults',
  'centerPoint',
  'videoMaskBoundingBox',
  'videoMaskType',
  'isLiftBackgroundLayer',
  'isMagicBackgroundLayer',
  'isLatest'
]

export const propertyMapping = {
  imageHeight: 'height',
  imageWidth: 'width',
  itemType: 'type',
}

export enum ObjectType {
  STATIC_VECTOR = 'StaticVector',
  GROUP = 'group',
  STATIC_PATH = 'StaticPath',
  DYNAMIC_PATH = 'DynamicPath',
  STATIC_IMAGE = 'StaticImage',
  DYNAMIC_IMAGE = 'DynamicImage',
  BACKGROUND_IMAGE = 'BackgroundImage',
  STATIC_TEXT = 'StaticText',
  DYNAMIC_TEXT = 'DynamicText',
  FRAME = 'Frame',
  BAZAART_STICKER = 'bazaart.item.sticker',
  BAZAART_TEXT = 'bazaart.item.text',
  BAZAART_BG = 'bazaart.item.background',
  BAZAART_IMAGE = 'bazaart.item.image',
  BAZAART_SHAP = 'bazaart.item.shape',
  BAZAART_OVERLAY = 'bazaart.item.overlay',
  BAZAART_DRAWING = 'bazaart.item.drawing',
  ACTIVE_SELECTION = 'activeSelection',
}

export enum ObjectTypeFromAPI {
  BACKGROUND_LAYER = 'bazaart.CanvasBackgroundLayer',
  IMAGE_LAYER = 'bazaart.CanvasImageLayer',
  TEXT_LAYER = 'bazaart.CanvasTextLayer',
  STICKER_LAYER = 'bazaart.CanvasStickerLayer',
  OVERLAY_LAYER = 'bazaart.CanvasOverlayLayer',
  SHAPE_LAYER = 'bazaart.CanvasShapeLayer',
  VECTOR_LAYER = 'bazaart.CanvasVectorLayer',
  PATH_LAYER = 'bazaart.CanvasPathLayer',
  DRAWING_LAYER = 'bazaart.CanvasPathLayer',
}

// export enum ObjectTypeAlt {
//   STATIC_IMAGE = 'image:static',
//   DYNAMIC_IMAGE = 'image:dynamic',
//   TEXTAREA = 'Textarea'
// }

export type DRAGGABLE_TYPE = ObjectType.BAZAART_IMAGE | ObjectType.BAZAART_STICKER | 'Template' | ObjectType.BAZAART_TEXT
export type ToolType = '' | 'Remove' | 'Eraser' | 'Enhance' | 'MagicBg'

export type ContextMenuDetails = {
  top: number
  left: number
} | null
export type POSITION = 'top' | 'left' | 'right' | 'bottom'
export const FRAME_PADDING_ADD_MENU = 88
export const FRAME_PADDING_INSPECTOR = 356

export const NAVBAR_HEIGHT = 64

export enum ScaleType {
  FILL,
  FIT,
}
export enum ShowModalType {
  CUSTOM_SIZE,
  UNSUPPORTED_FILE_TYPE,
  DISCARD_PROJECT,
  SAVE_PROJECT,
  DELETE_PROJECT,
  DOWNLOAD_PROJECT,
  DOWNLOAD_APP,
  QUICK_TUTORIAL,
  CANCEL_TUTORIAL,
  GOOD_WORK_MODAL,
  PROJECT_NOT_SUPPORT,
  PROJECT_STILL_SYNC,
  PROJECT_OPEN_FAILED,
  PROJECT_ALREADY_OPEN,
  PROJECT_DOESNT_EXIST,
  PROJECT_CONFLICT,
  ONBOARDING,
  CONFIRM_SIGN_OUT,
  NO_INTERNET,
  SIGN_IN,
  WHAT_NEW,
  ENHANCE_ERROR,
  NSFW_ERROR,
  AI_IMAGE_ERROR,
}

export const LIST_LANGUAGE = [
  // { id: 2, label: 'العربية', desc: 'Arabic', value: 'ar'},
  { id: 1, label: 'English', desc: 'English', value: 'en'},
  { id: 3, label: 'Français', desc: 'French', value: 'fr' },
  { id: 4, label: 'Deutsch', desc: 'German', value: 'de' },
  // { id: 5, label: 'עברית', desc: 'Hebrew', value: 'he' },
  { id: 6, label: 'Italiano', desc: 'Italian', value: 'it' },
  { id: 7, label: '日本語', desc: 'Japanese', value: 'ja' },
  { id: 8, label: '한국어', desc: 'Korean', value: 'ko' },
  { id: 9, label: 'Português', desc: 'Portuguese - brazil', value: 'pt' },
  { id: 10, label: 'Русский', desc: 'Russian', value: 'ru' },
  { id: 11, label: '简体中文', desc: 'Simplified Chinese', value: 'zh-Hans' },
  { id: 12, label: 'Español', desc: 'Spanish', value: 'es' },
  { id: 13, label: '繁體中文', desc: 'Traditional Chinese', value: 'zh-Hant' },
  { id: 14, label: 'Türkçe', desc: 'Turkish', value: 'tr' },
]

export const SHOW_DOWNLOAD_APP_TIME_DURATION = 3000

export enum SAVE_PROJECT_STATE {
  INIT,
  LOADING,
  SUCCESS,
  END
}

export const DEFAULT_FONT = 'Poppins-SemiBold'
export const DARK_OVERLAY_EXPOSURE_VALUE = -1.08

export enum TEXT_ALIGN {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}