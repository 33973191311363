import Background from './Background'
import Photos from './Photos'
import Text from './Text'
import Templates from './Templates'
import Search from './Search'
import Images from './Images'
import Graphics from './Graphics'
import Pixabay from './Pixabay'
import Uploads from './Uploads'
import Overlay from './Overlay'
import Back from './Back'
import Resize from './Resize'
import Transparent from './Transparent'
import Vectors from './Vectors'
import Stock from './Stock'
import AIImage from './AIImage'

class Icons {
  static Background = Background
  static Photos = Photos
  static Text = Text
  static Templates = Templates
  static Search = Search
  static Images = Images
  static Graphics = Graphics
  static Pixabay = Pixabay
  static Uploads = Uploads
  static Overlay = Overlay
  static Back = Back
  static Resize = Resize
  static Transparent = Transparent
  static Vectors = Vectors
  static Close: any
  static Stock = Stock
  static AIImage = AIImage
}

export default Icons
